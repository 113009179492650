<template>
  <div style="display: flex;justify-content: center">
    <div v-if="login" class="col-md-10 col-xs-12">
      <table class="table table-bordered table-hover table-striped">
        <thead>
          <tr>
            <th>用户</th>
            <th width="20%">IP</th>
            <th width="20%">地址</th>
            <th width="20%">时间</th>
            <th width="50px">记录</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in list" :key="item.id" v-on:click="showDetail(item.uuid, 1)">
            <td>{{item.uuid}}</td>
            <td>{{item.ip}}</td>
            <td>{{item.ipAddr}}</td>
            <td>{{item.updateTime}}</td>
            <td style="text-align: right">{{item.count}}</td>
          </tr>
        </tbody>
      </table>
      <nav style="text-align: center;">
        <ul class="pagination" style="margin: 0">
          <li v-for="item in listPages" :key="item" :class="selectPage === item ? 'active' : ''" v-on:click="getData(item)">
            <span href="#">{{item}}<span class="sr-only">(current)</span></span>
          </li>
        </ul>
      </nav>
    </div>

    <div v-if="!login" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);color: #5e5e5e;font-size: 20px">
      请先登录
    </div>

    <div class="modal fade" id="MAInfoModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content" style="border-radius: 25px">
          <div class="modal-body" style="text-align: center;background-color: red;border-radius: 25px">
            <span style="color: white;font-size: 15px">{{systemInfo}}</span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="VisitorModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="myModalLabel">访问路径</h4>
          </div>
          <div class="modal-body">
            <table class="table table-bordered table-hover table-striped" style="">
              <thead>
              <tr>
                <th>资源</th>
                <th>请求</th>
                <th>时间</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in detailList" :key="item.id">
                <td>{{item.path}}<span style="float: right" class="badge">{{item.label}}</span></td>
                <td>{{item.method}}</td>
                <td>{{item.createTime}}</td>
              </tr>
              </tbody>
            </table>
            <nav style="text-align: center;">
              <ul class="pagination" style="margin: 0">
                <li v-for="item in detailPages" :key="item" :class="detailSelectPage === item ? 'active' : ''" v-on:click="showDetail(selectUuid, item)">
                  <span href="#">{{item}}<span class="sr-only">(current)</span></span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import Cookies from "js-cookie";
import GLOBAL from "@/global_variable";

export default {
  name: 'visitor',
  data: function() {
    return {
      list: [],
      detailList: [],
      login: false,
      systemInfo: '',
      listLimit: 15,
      listOffset: 0,
      listCount: true,
      listPages: [],
      selectPage: 0,
      selectUuid: null,
      detailLimit: 10,
      detailOffset: 0,
      detailCount: true,
      detailPages: [],
      detailSelectPage: 0
    }
  },
  methods: {
    getData(page) {
      if (window.localStorage.getItem('accessToken')) {
        this.$http.get(GLOBAL.apiUrl + "/visitor/list", {params: {count: this.listCount, limit: this.listLimit, offset: (page-1) * this.listLimit}}).then(response => {
          const res = response['data'];
          if (res['code'] === 0) {
            this.list = res['data']['data'];
            this.login = true;
            if (this.listCount) {
              for (let i = 0; i < (res['data']['total'] / this.listLimit); i++) {
                this.listPages.push(i + 1);
              }
              this.listCount = false;
            }
            this.selectPage = page;
          }
        }, err => {
          this.systemInfo = err['data']['message'];
          this.$('#MAInfoModal').modal()
          location.reload();
        })
      } else {
        this.login = false;
      }
    },
    showDetail(uuid, page) {
      if (this.selectUuid !== uuid) {
        this.detailCount = true;
      }
      if (window.localStorage.getItem('accessToken')) {
        this.$http.get(GLOBAL.apiUrl + "/visitor/" + uuid, {params: {count: this.detailCount, limit: this.detailLimit, offset: (page-1) * this.detailLimit}}).then(response => {
          const res = response['data'];
          if (res['code'] === 0) {
            this.detailList = res['data']['data'];
            this.login = true;
            this.detailList.forEach(item => {
              const queryParam = item.query === null ? {} : JSON.parse(item.query);
              const bodyParam = item.body == null ? {} : JSON.parse(item.body);
              item.label = queryParam.name || bodyParam.name;
              if (Array.isArray(item.label)) {
                item.label = item.label[0]
              }
            })
            this.$("#VisitorModal").modal();
            if (this.detailCount) {
              this.detailPages = [];
              for (let i = 0; i < (res['data']['total'] / this.detailLimit); i++) {
                this.detailPages.push(i + 1);
              }
              this.detailCount = false;
            }
            this.detailSelectPage = page;
            this.selectUuid = uuid
          }
        }, err => {
          this.systemInfo = err['data']['message'];
          this.$('#MAInfoModal').modal()
          location.reload();
        })
      } else {
        this.login = false;
      }
    },
  },
  created() {
    this.getData(1);
    this.$('#MAInfoModal').on('close.bs.alert', function () {
      location.reload();
    })
  }
}
</script>
