<template>
  <div class="bill">
    <div style="display: flex; flex-wrap: wrap;">
      <button v-for="item in accountList" :key="item.id" type="button" :class="['btn', accountNow == item.id? 'btn-primary':'']" style="margin: 0 10px 10px 0" @click="selectAccount(item)">
        <span :class="[item.type == 1? 'glyphicon glyphicon-user' : 'glyphicon glyphicon-cloud']"></span>
        {{ item.name }}
        <span v-show="item.id == accountNow" class="glyphicon glyphicon-pencil" style="margin: 0 0 0 10px" @click.stop="editAccount(item)"></span>
        <span v-show="item.id == accountNow" class="glyphicon glyphicon-trash" style="margin: 0 0 0 10px" @click.stop="deleteAccount(item)"></span>
      </button>
      <button type="button" class="btn btn-primary" @click="addAccount" style="margin: 0 10px 10px 0">+</button>
      <button type="button" class="btn btn-primary" @click="clickButton" style="margin: 0 10px 10px 0">记账</button>
    </div>
    <hr>
    <div style="display: flex; flex-wrap: wrap; align-items: center;">
      <span v-for="item in classifyList" :key="item.id" style="margin: 0 5px 0 5px;">
        <input type="radio" name="type" :value="item.id" v-model="selectType" style="margin: 0 5px 0 0" />{{ item.name }}
      </span>
      <span style="margin: 0 15px 0 5px;">
        <input type="radio" name="type" value="" v-model="selectType" style="margin: 0 5px 0 0" />总览
      </span>
      <!-- <button type="button" class="btn btn-primary" @click="clickAll">总览</button> -->
      <input type="date" style="width: 110px" v-model="startTime" @change="changeTime(1)" >&nbsp;
      <input type="date" style="width: 110px" v-model="endTime" @change="changeTime(2)">

    </div>
    <div style="width: 100%;">
      <section class="col-sm-12 col-md-6" id="echart1"></section>

      <!-- <section class="col-sm-12 col-md-6" id="echart2"></section> -->
      <section style="width: 100%; padding: 0 10px 10px 10px;">
        <table style="width: 100%; margin-bottom: 10px;">
          <thead style="display: block; width: 100%; font-size: 14px; font-weight: 800;">
            <tr style="display: flex; width: 100%">
              <td>分类</td>
              <td>金额</td>
              <td>记录时间</td>
              <td>备注</td>
              <td>操作</td>
            </tr>
          </thead>
          <tbody style="display: block; width: 100%; height: calc(100vh - 590px); overflow: auto">
            <tr v-for="item in list" :key="item.id" style="display: flex; width: 100%">
              <td style="color: #999; font-weight: 800">{{item.typeName}}</td>
              <td style="font-weight: 800">
                <span :style="{color: (item.classify == 2? '#ff6363' : '#77c377')}">
                  {{ item.classify == 2? "- " : "+ " }}{{item.money}}
                </span>
                <span style="color: #999">{{ "(" + item.remain + ")" }}</span>
              </td>
              <td style="font-size: 12px">{{item.recordTime}}</td>
              <td style="font-size: 12px; color: #999">{{item.descript}}</td>
              <td>
                <span class="glyphicon glyphicon-pencil" style="margin: 0 10px 0 0" @click.stop="editRecord(item)"></span>
                <span v-show="item.canDelete" class="glyphicon glyphicon-trash" @click.stop="deleteRecord(item)"></span>
              </td>
            </tr>
          </tbody>
        </table>
        <nav style="text-align: center;">
          <ul class="pagination" style="margin: 0 auto">
            <li v-for="item in detailPages" :key="item" :class="detailSelectPage === item ? 'active' : ''" v-on:click="showDetail(item)">
              <span href="#">{{item}}<span class="sr-only">(current)</span></span>
            </li>
          </ul>
        </nav>
      </section>
    </div>

    <!-- 记账小本 -->
    <div class="modal fade" id="setoutModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" data-backdrop="static">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="myModalLabel">{{ isEditRecord? '编辑记录' : '记账小本本' }}</h4>
          </div>
          <div class="modal-body">
            <form class="form-inline">
              <div style="margin: 0 0 10px 0; display: flex;">
                <section v-for="item in classifyList" :key="item.id" style="display: flex; margin: 0 10px 0 0;">
                  <input type="radio" name="type" :value="item.id" v-model="addSelectType" style="margin: 0 10px 0 0" :disabled="isEditRecord">{{ item.name }}
                </section>
              </div>
              <div style="margin: 0 0 10px 0; display: flex;">
                <label for="contents" style="margin-right: 10px">类别：</label>
                <div class="dropdown">
                  <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" :disabled="isEditRecord">
                    {{ selectTypevalue.name }}
                    <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                    <li style="padding: 10px; cursor: pointer" v-for="item in typeList" :key="item.id" @click="selectTypeValue(item)">{{ item.name }}</li>
                  </ul>
                </div>
              </div>
              <div style="margin: 0 0 10px 0">
                <label for="contents" style="margin-right: 10px">金额：</label>
                <input class="form-control" v-model.number="money" placeholder="请输入金额" :disabled="isEditRecord">
              </div>
              <div style="margin: 0 0 10px 0">
                <label for="contents" style="margin-right: 10px">备注：</label>
                <input class="form-control" v-model="billDes" placeholder="请输入备注">
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
            <button v-show="!isEditRecord" type="button" class="btn btn-primary" @click="addBill()">确认添加</button>
            <button v-show="isEditRecord" type="button" class="btn btn-primary" @click="editBill()">确认编辑</button>
            <br />
          </div>
        </div>
      </div>
    </div>

    <!-- 类型列表 -->
    <div class="modal fade" id="typeDetailListModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" data-backdrop="static" data-target=".bs-example-modal-lg">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="myModalLabel">{{ typeDetailName }}</h4>
          </div>
          <div class="modal-body">
            <section style="width: 100%; padding: 0 10px 10px 10px;">
              <table style="width: 100%; margin-bottom: 10px;">
                <thead style="display: block; width: 100%; font-size: 14px; font-weight: 800;">
                <tr style="display: flex; width: 100%">
                  <td>分类</td>
                  <td>金额</td>
                  <td>记录时间</td>
                  <td>备注</td>
                </tr>
                </thead>
                <tbody style="display: block; width: 100%; height: calc(100vh - 590px); overflow: auto">
                <tr v-for="item in typeDetailList" :key="item.id" style="display: flex; width: 100%">
                  <td style="color: #999; font-weight: 800">{{item.typeName}}</td>
                  <td style="font-weight: 800">
                <span :style="{color: (item.classify == 2? '#ff6363' : '#77c377')}">
                  {{ item.classify == 2? "- " : "+ " }}{{item.money}}
                </span>
                    <span style="color: #999">{{ "(" + item.remain + ")" }}</span>
                  </td>
                  <td style="font-size: 12px">{{item.recordTime}}</td>
                  <td style="font-size: 12px; color: #999">{{item.descript}}</td>
                </tr>
                </tbody>
              </table>
              <nav style="text-align: center;">
                <ul class="pagination" style="margin: 0 auto">
                  <li v-for="item in typeDetailPages" :key="item" :class="typeDetailSelectPage === item ? 'active' : ''" v-on:click="showTypeDetail(item)">
                    <span href="#">{{item}}<span class="sr-only">(current)</span></span>
                  </li>
                </ul>
              </nav>
            </section>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">关闭</button>
            <br />
          </div>
        </div>
      </div>
    </div>

    <!-- 添加账户 -->
    <div class="modal fade" id="addAccountModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" data-backdrop="static">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="myModalLabel">{{isEditAccount? '编辑账户' : '添加账户'}}</h4>
          </div>
          <div class="modal-body">
            <form class="form-inline">
              <div style="margin: 0 0 10px 0">
                <label for="contents" style="margin-right: 10px">名称：</label>
                <input class="form-control" v-model="accountName" placeholder="请输入名称">
              </div>
              <div style="margin: 0 0 10px 0; display: flex;">
                <label for="contents" style="margin-right: 10px">类型：</label>
                <section style="display: flex; justify-content: flex-start; align-items: center">
                  <input type="radio" name="type" value="1" v-model="selectPublic" style="margin: 0 10px 0 0">私有
                  <input type="radio" name="type" value="2" v-model="selectPublic" style="margin: 0 10px 0 10px">公共
                </section>
              </div>
              <div style="margin: 0 0 10px 0">
                <label for="contents" style="margin-right: 10px">金额：</label>
                <input class="form-control" v-model="accountMoney" placeholder="请输入金额" :disabled="isEditAccount">
              </div>
              <div style="margin: 0 0 10px 0">
                <label for="contents" style="margin-right: 10px">描述：</label>
                <input class="form-control" v-model="accountDec" placeholder="请输入描述">
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
            <button v-show="!isEditAccount" type="button" class="btn btn-primary" @click="addSubmit()">确认添加</button>
            <button v-show="isEditAccount" type="button" class="btn btn-primary" @click="editSubmit()">确认编辑</button>
            <br />
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="MAInfoModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content" style="border-radius: 25px">
          <div class="modal-body" style="text-align: center;background-color: lightblue;border-radius: 25px">
            <span style="color: yellow;font-size: 15px">{{systemInfo}}</span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import GLOBAL from "@/global_variable";
import moment from "moment";


export default {
  name: 'bill',
  data() {
    return {
      myChart1: null,
      myChart2: null,

      accountList: [],
      allList: [],
      list: [],
      typeDetailName: null,
      typeDetailTypeId: null,
      typeDetailList: [],
      money: 0,
      selectType: "",
      addSelectType: "",
      billDes: '',
      recordNow: {},
      isEditRecord: false,

      systemInfo: '',
      classifyList: [],
      typeList: [],
      selectTypevalue: { name: "请选择类别" },
      selectPublic: 1,

      accountMoney: null,
      accountName: '',
      accountDec: '',
      accountNow: null,
      isEditAccount: false,
      accountEdit: null,

      startTime: moment(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-01').format("YYYY-MM-DD"),
      endTime: moment(new Date()).format("YYYY-MM-DD"),

      detailPages: [],
      detailSelectPage: 1,
      detailLimit: 20,

      typeDetailPages: [],
      typeDetailSelectPage: 1,
      typeDetailLimit: 20,
    }
  },
  mounted() {
    this.drawCharts1();
    // this.drawCharts2();
    this._getClassify();
    this._getTypeList();
    this._getAccountList();
  },
  watch: {
    selectType () {
      // this.selectTypevalue = { name: "请选择类别" }
      // this._getTypeList();
      this.detailSelectPage = 1
      this._getList()
      this._getAll()
    },
    addSelectType () {
      this.selectTypevalue = { name: "请选择类别" }
      this._getTypeList();
    }
  },
  methods: {
    drawCharts1() {
      this.myChart1 = echarts.init(document.getElementById('echart1'));
      // 绘制图表
      this.myChart1.setOption({
        title: {
          // text: ''
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            // name: '收入',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: '吃的' },
              { value: 735, name: '购物' },
            ]
          }
        ]
      });
      this.myChart1.on("click", param => {
        this._getTypeDetail(param)
      });
    },
    drawCharts2() {
      this.myChart2 = echarts.init(document.getElementById('echart2'));
      // 绘制图表
      this.myChart2.setOption({
        title: {
          text: '余额'
        },
        tooltip: {},
        xAxis: {
          type: 'category',
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        },
        yAxis: {
          type: 'value'
        },
        legend: {
          top: '2%',
          left: 'center'
        },
        series: [
          {
            name: '',
            type: 'line',
            data: [10000, 8000, 9000, 10000, 10000, 9000]
          }
        ]
      });
    },
    _getClassify() {
      this.$http.get(GLOBAL.apiUrl + "/book/classify/list").then(response => {
        const res = response['data'];
        if (res['code'] === 0) {
          this.classifyList = res['data'];
        }
      }, err => {
        this.systemInfo = err['data']['message'];
        this.$('#MAInfoModal').modal()
      })
    },
    _getTypeList() {
      this.$http.get(GLOBAL.apiUrl + "/book/type/list", {params: { classify: this.addSelectType }}).then(response => {
        const res = response['data'];
        if (res['code'] === 0) {
          this.typeList = res['data'];
        }
      }, err => {
        this.systemInfo = err['data']['message'];
        this.$('#MAInfoModal').modal()
      })
    },
    _getAll() {
      if (this.startTime && this.endTime) {
        this.$http.get(GLOBAL.apiUrl + "/book/record/summary", {params: { classify: this.selectType, account: this.accountNow, endTime: moment(this.endTime).format("YYYY-MM-DD"),
          startTime: moment(this.startTime).format("YYYY-MM-DD") }}).then(response => {
          const res = response['data'];
          if (res['code'] === 0) {
            this.allList = res['data'];
            let temp = []
            this.allList.forEach(item => {
              let a = {
                groupId: item.classify,
                typeId: item.type,
                value: item.total,
                name: item.name + (item.classify === 1 ? "【收】" : "【支】"),
                tooltip: {
                  formatter: "<span style='font-weight: bold'>{b}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span style='color:" + (item.classify === 2 ? "#ff6363" : "#77c377") + ";font-weight: bold'> " + (item.classify === 2 ? "-" : "+") + "{c}</span>"}
              }
              temp.push(a)
            })
            this.myChart1.setOption({
              series: [{
                data: temp
              }]
            })
          }
        }, err => {
          this.systemInfo = err['data']['message'];
          this.$('#MAInfoModal').modal()
        })
      }
    },
    changeTime(val) {
      if (val == 1) {
        this.endTime = null
      }
      if (this.endTime && this.startTime) {
        if (new Date(this.endTime).getTime() < new Date(this.startTime).getTime()) {
          this.endTime = null
          this.systemInfo = "开始时间应小于结束时间！";
          this.$('#MAInfoModal').modal()
        } else {
          this._getList()
          this._getAll()
        }
      }
    },
    // 账户
    addAccount() {
      this.$('#addAccountModal').modal()
      this.resetAccount()
    },
    _getAccountList() {
      this.$http.get(GLOBAL.apiUrl + "/book/account/list").then(response => {
        const res = response['data'];
        if (res['code'] === 0) {
          this.accountList = res['data'];
          this.accountNow = this.accountList.length? this.accountList[0].id : null
          this._getList()
          this._getAll()
        }
      }, err => {
        this.systemInfo = err['data']['message'];
        this.$('#MAInfoModal').modal()
      })
    },
    addSubmit() {
      if (!this.accountName) {
        alert("请填写名称！")
      } else if(!this.accountMoney) {
        alert("请输入金额！")
      } else {
        this.$http.post(GLOBAL.apiUrl + "/book/account/add", {
          name: this.accountName,
          money: this.accountMoney,
          descript: this.accountDec,
          type: this.selectPublic
        }).then(response => {
          const res = response['data'];
          if (res['code'] === 0) {
            this.$('#addAccountModal').modal('hide')
            this._getAccountList();
            this.isEditAccount = false;
            this.systemInfo = "添加成功！"
          } else {
            this.systemInfo = res['message'];
          }
          this.$('#MAInfoModal').modal()
        }, err => {
          this.systemInfo = err['body']['message'];
          this.$('#MAInfoModal').modal()
        });
      }
    },
    editSubmit() {
      this.$http.post(GLOBAL.apiUrl + "/book/account/edit", {
        name: this.accountName,
        descript: this.accountDec,
        id: this.accountEdit,
        type: this.selectPublic
      }).then(response => {
        const res = response['data'];
        if (res['code'] === 0) {
          this.$('#addAccountModal').modal('hide')
          this._getAccountList();
          this.isEditAccount = false;
          this.systemInfo = "编辑成功！"
        } else {
          this.systemInfo = res['message'];
        }
        this.$('#MAInfoModal').modal()
      }, err => {
        this.systemInfo = err['body']['message'];
        this.$('#MAInfoModal').modal()
      });
    },
    selectAccount(val) {
      if (val.id == this.accountNow) {
        // 重复点击
      } else {
        this.detailSelectPage = 1
        this.accountNow = val.id
        this._getList()
        this._getAll()
      }
    },
    editAccount(val) {
      this.$('#addAccountModal').modal();
      this.isEditAccount = true;
      this.accountName = val.name,
      this.accountMoney = val.money,
      this.accountDec = val.descript,
      this.accountEdit = val.id,
      this.selectPublic = val.type
    },
    deleteAccount(val) {
      if (confirm("确认删除？")) {
        this.$http.post(GLOBAL.apiUrl + "/book/account/delete/" + val.id).then(response => {
          const res = response['data'];
          if (res['code'] === 0) {
            this.systemInfo = "删除成功！"
            this._getAccountList()
          } else {
            this.systemInfo = res['message'];
          }
          this.$('#MAInfoModal').modal()
        }, err => {
          this.systemInfo = err['body']['message'];
          this.$('#MAInfoModal').modal()
        });
      }
    },
    resetAccount() {
      this.isEditAccount = false;
      this.accountName = '',
      this.accountMoney = null,
      this.accountDec = '',
      this.accountEdit = '',
      this.selectPublic = 1
    },

    // 记账
    clickButton() {
      this.$('#setoutModal').modal()
      this.resetBill()
    },
    addBill() {
      if (!this.selectTypevalue.id) {
        alert("请选择类别！")
      } else if(!this.money) {
        alert("请选择金额！")
      } else {
        this.$http.post(GLOBAL.apiUrl + "/book/record/add", {
          "accountId": this.accountNow,
          "classify": this.addSelectType,
          "descript": this.billDes,
          "money": this.money,
          "type": this.selectTypevalue.id
        }).then(response => {
          const res = response['data'];
          if (res['code'] === 0) {
            this.$('#setoutModal').modal('hide')
            this._getList();
            this._getAll()
            this.systemInfo = "添加成功！"
          } else {
            this.systemInfo = res['message'];
          }
          this.$('#MAInfoModal').modal()
        }, err => {
          this.systemInfo = err['body']['message'];
          this.$('#MAInfoModal').modal()
        });
      }
    },

    _getTypeDetail(param) {
      if (param !== undefined) {
        this.typeDetailName = param.data.name;
        this.typeDetailTypeId = param.data.typeId;
      }
      let params = {
        account: this.accountNow,
        type: this.typeDetailTypeId,
        endTime: moment(this.endTime).format("YYYY-MM-DD"),
        startTime: moment(this.startTime).format("YYYY-MM-DD"),
        limit: this.typeDetailLimit,
        offset: (this.typeDetailSelectPage - 1) * this.typeDetailLimit,
        count: 1
      }
      this.$http.get(GLOBAL.apiUrl + "/book/record/list", {params: params}).then(response => {
        const res = response['data'];
        if (res['code'] === 0) {
          this.typeDetailList = res['data']['data'];
          this.typeDetailPages = [];
          for (let i = 0; i < (res['data']['total'] / this.typeDetailLimit); i++) {
            this.typeDetailPages.push(i + 1);
          }
          let tempRemain = []
          let tempX = []
          this.typeDetailList.forEach(item => {
            tempRemain.push(item.remain)
            tempX.push(item.recordTime)
          })
          this.$('#typeDetailListModal').modal()
        } else {
          this.systemInfo = res['message'];
          this.$('#MAInfoModal').modal()
        }
      }, err => {
        this.systemInfo = err['data']['message'];
        this.$('#MAInfoModal').modal()
      })
    },

    // 获取记录
    _getList() {
      if (this.startTime && this.endTime) {
        let params = {
          account: this.accountNow,
          classify: this.selectType,
          endTime: moment(this.endTime).format("YYYY-MM-DD"),
          startTime: moment(this.startTime).format("YYYY-MM-DD"),
          limit: this.detailLimit,
          offset: (this.detailSelectPage - 1) * this.detailLimit,
          count: 1
        }
        this.$http.get(GLOBAL.apiUrl + "/book/record/list", {params: params}).then(response => {
          const res = response['data'];
          if (res['code'] === 0) {
            this.list = res['data']['data'];
            this.detailPages = [];
            for (let i = 0; i < (res['data']['total'] / this.detailLimit); i++) {
              this.detailPages.push(i + 1);
            }
            let tempRemain = []
            let tempX = []
            this.list.forEach(item => {
              tempRemain.push(item.remain)
              tempX.push(item.recordTime)
            })
            // this.myChart2.setOption({
            //   xAxis: {
            //     data: tempX.reverse()
            //   },
            //   series: [{
            //     data: tempRemain.reverse()
            //   }]
            // })
          } else {
            this.systemInfo = res['message'];
            this.$('#MAInfoModal').modal()
          }
        }, err => {
          this.systemInfo = err['data']['message'];
          this.$('#MAInfoModal').modal()
        })
      }
    },
    selectTypeValue(val) {
      this.selectTypevalue = val
    },
    editRecord(val) {
      this.$('#setoutModal').modal()
      this.recordNow = val
      this.isEditRecord = true
      this.addSelectType = val.classify
      this.billDes = val.descript
      this.money = val.money
      this.selectTypevalue = { id: val.type, name: val.typeName }
    },
    deleteRecord(val) {
      if (confirm("确认删除？")) {
        this.$http.post(GLOBAL.apiUrl + "/book/record/delete/" + val.id).then(response => {
          const res = response['data'];
          if (res['code'] === 0) {
            this.systemInfo = "删除成功！"
            this._getList();
            this._getAll()
          } else {
            this.systemInfo = res['message'];
          }
          this.$('#MAInfoModal').modal()
        }, err => {
          this.systemInfo = err['body']['message'];
          this.$('#MAInfoModal').modal()
        });
      }
    },
    editBill() {
      this.$http.post(GLOBAL.apiUrl + "/book/record/edit", {
        "id": this.recordNow.id,
        "descript": this.billDes,
        "type": this.recordNow.type
      }).then(response => {
        const res = response['data'];
        if (res['code'] === 0) {
          this.$('#setoutModal').modal('hide')
          this._getList();
          this._getAll()
          this.systemInfo = "编辑成功！"
        } else {
          this.systemInfo = res['message'];
        }
        this.$('#MAInfoModal').modal()
      }, err => {
        this.systemInfo = err['body']['message'];
        this.$('#MAInfoModal').modal()
      });
    },
    resetBill() {
      this.isEditRecord = false
      this._getTypeList()
      this.billDes = ''
      this.money = null
      this.addSelectType = ""
      this.selectTypevalue = { name: "请选择类别" }
    },
    clickAll() {
      this.selectType = null
      this._getTypeList()
    },
    showDetail(val) {
      this.detailSelectPage = val
      this._getList()
    },
    showTypeDetail(val) {
      this.typeDetailSelectPage = val
      this._getTypeDetail()
    }
  }
}
</script>

<style scoped>
.bill {
  width: 100%;
  padding: 0 10px;
}
ul li:hover {
  background-color: #ccc;
}
#echart1 {
  /* width: 90%; */
  height: 300px;
}
#echart2 {
  /* width: 90%; */
  height: 300px;
}
tr td {
  padding: 5px;
}
tr td:nth-child(1){
  width: 15%;
}
tr td:nth-child(2){
  width: 30%;
}
tr td:nth-child(3){
  width: 20%;
}
tr td:nth-child(4){
  width: 20%;
}
tr td:nth-child(5){
  width: 15%;
}
</style>
