<template>
  <div style="display: flex;justify-content: center">
    <div v-if="login" class="col-md-10 col-xs-12">

      <div style="min-height: 44px;text-align: right">
        <button class="btn btn-info" style="margin: 10px 0;" @click="mode='add';resetSelect();openAddTypeDialog()">添加</button>
      </div>

      <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
        <div class="panel panel-info" v-for="(t, i) in type" :key="t.id">
          <div class="panel-heading" role="tab" :id="`headingOne${t.id}`" style="position: relative; padding: 0;">
            <a role="button" data-toggle="collapse" data-parent="#accordion" :href="`#collapseOne${t.id}`" aria-expanded="true" :aria-controls="`collapseOne${t.id}`" @click="getData(t.id)" style="display: block; padding: 10px 30px;">
              <section style="position: absolute; top: 10px; left: 10px">
                <div v-show="t.id !== recordType || !recordType" class="glyphicon glyphicon-chevron-right"></div>
                <div v-show="t.id == recordType" class="glyphicon glyphicon-chevron-down"></div>
              </section>
              {{ t.name }}
            </a>
            <button class="btn btn-info btn-xs" style="position: absolute; top: 10px; right: 10px;" @click="mode='add';resetSelect();openAddDialog()">添加</button>
          </div>
          <div :id="`collapseOne${t.id}`" :class="i === 0 ? 'in' : ''" class="panel-collapse collapse" role="tabpanel" :aria-labelledby="`headingOne${t.id}`">
            <div class="panel-body">
              <table class="table table-bordered table-hover table-striped">
                <thead>
                <tr>
                  <th width="20%">网站</th>
                  <th>用户名</th>
                  <th width="15%">保密</th>
                  <th width="35%">操作</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in data" :key="item.id">
                  <td>{{item.site}}</td>
                  <td>{{item.username}}</td>
                  <td>{{item.type + '级'}}</td>
                  <td>
                    <span class="glyphicon glyphicon-pencil" @click="mode='edit';select=item;openAddDialog()"></span>
                    <span class="glyphicon glyphicon-trash" style="margin-left: 10px" @click="openRemoveDialog(item)"></span>
                    <span class="glyphicon glyphicon-eye-open" style="margin-left: 10px" @click="select=item;query(item.id)"></span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!login" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);color: #5e5e5e;font-size: 20px">
      请先登录
    </div>

    <!-- Modal -->
    <div class="modal fade" id="addModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" data-backdrop="static">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="myModalLabel">{{mode==='add' ? '添加' : '修改'}}</h4>
          </div>
          <div class="modal-body" style="display: flex">
            <form class="form-inline" style="padding-right: 10px;border-right: 1px solid #cccccc">
              <div class="form-group">
                <label class="hidden-xs" for="username" style="letter-spacing: 14px">网站</label>
                <input type="email" class="form-control" id="site" v-model="select.site" placeholder="网站">
              </div>
              <br class="hidden-xs" />
              <br class="hidden-xs" />
              <div class="form-group">
                <label class="hidden-xs" for="username" style="margin-right: 12px">用户名</label>
                <input type="email" class="form-control" id="usernameAccount" v-model="select.username" placeholder="用户名">
              </div>
              <br class="hidden-xs" v-if="mode==='edit'" />
              <br class="hidden-xs" v-if="mode==='edit'" />
              <div v-if="mode==='edit'" class="form-group">
                <label class="hidden-xs" for="password" style="margin-right: 12px">旧密码</label>
                <input type="password" class="form-control" id="oldPassword" v-model="select.oldPassword" placeholder="旧密码">
              </div>
              <br class="hidden-xs" />
              <br class="hidden-xs" />
              <div class="form-group">
                <label class="hidden-xs" for="password" style="letter-spacing: 14px">密码</label>
                <input type="password" class="form-control" id="passwordAccount" v-model="select.password" placeholder="密码">
              </div>
              <br class="hidden-xs" />
              <br class="hidden-xs" />
              <div class="form-group">
                <label class="hidden-xs" for="password">密码确认</label>
                <input type="password" class="form-control" id="passwordConfirm" v-model="select.passwordConfirm" placeholder="密码确认">
              </div>
              <br class="hidden-xs" />
              <br class="hidden-xs" />
              <div>
                <label class="radio-inline" style="margin-right: 20px">
                  <input type="radio" name="type" id="type1" value="1" v-model="select.type"> 1级保密
                </label>
                <label class="radio-inline" style="margin-right: 20px">
                  <input type="radio" name="type" id="type2" value="2" v-model="select.type"> 2级保密
                </label>
                <label class="radio-inline">
                  <input type="radio" name="type" id="type3" value="3" v-model="select.type"> 3级保密
                </label>
              </div>
            </form>
            <div>
              <ul>
                <li>1级保密：可<b>直接</b>查看密码</li>
                <br />
                <li>2级保密：通过<b>口令</b>查看密码</li>
                <br />
                <li>3级保密：通过<b>口令</b>加<b>动态验证码</b>查看密码</li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <span style="color: #ff6565;font-size: 15px;margin-right: 10px">{{systemInfo}}</span>
            <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
            <button type="button" class="btn btn-primary" @click="add()">{{mode==='add' ? '添加' : '修改'}}</button>
            <br />
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="queryModal" tabindex="-1" role="dialog" aria-labelledby="queryModalLabel" aria-hidden="false" data-backdrop="static">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="queryModalLabel">查看密码</h4>
          </div>
          <div class="modal-body" style="display: flex">
            <form class="form-inline">
              <div class="form-group">
                <label class="hidden-xs" for="username" style="letter-spacing: 12px">口令</label>
                <input type="password" class="form-control" id="token" v-model="queryParam.token" placeholder="口令">
              </div>
              <br class="hidden-xs" />
              <br class="hidden-xs" />
              <div class="form-group" v-if="select.type === 3">
                <label class="hidden-xs" for="username" style="margin-right: 12px">验证码</label>
                <input type="text" class="form-control" id="ma" v-model="queryParam.ma" placeholder="验证码">
                <br />
                <br class="hidden-xs" />
                <button type="button" :class="'btn btn-primary ' + disabled" @click="getMa()">
                  {{'获取验证码 ' + (maKeyInterval > 0 ? maKeyInterval : '')}}
                </button>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <span style="color: #ff6565;font-size: 15px;margin-right: 10px">{{systemInfo}}</span>
            <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
            <button type="button" class="btn btn-primary" @click="query2(select.id)">查看</button>
            <br />
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="passwordModal" tabindex="-1" role="dialog" aria-labelledby="passwordModalLabel" aria-hidden="false" data-backdrop="static">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="passwordModalLabel">查看密码</h4>
          </div>
          <div class="modal-body" style="text-align: center;">
            <p style="padding: 10px;border: 1px solid #cccccc;background-color: #dddddd"><b>{{result}}</b></p>
          </div>
          <div class="modal-footer">
            <span style="color: #ff6565;font-size: 15px;margin-right: 10px">{{systemInfo}}</span>
            <button type="button" class="btn btn-default" data-dismiss="modal">关闭</button>
            <br />
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="addTypeModal" tabindex="-1" role="dialog" aria-labelledby="addTypeModalLabel" aria-hidden="false" data-backdrop="static">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="addTypeLabel">添加分类</h4>
          </div>
          <div class="form-group" style="padding: 10px">
            <input type="text" class="form-control" id="accountType" v-model="newType.name" placeholder="分类名称">
          </div>
          <div class="modal-footer">
            <span style="color: #ff6565;font-size: 15px;margin-right: 10px">{{systemInfo}}</span>
            <button type="button" class="btn btn-default" data-dismiss="modal" @click="newType.name = null">关闭</button>
            <button type="button" class="btn btn-primary" @click="addType()">添加</button>
            <br />
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="removeModal" tabindex="-1" role="dialog" aria-labelledby="removeModalLabel" aria-hidden="false" data-backdrop="static">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="removeModalLabel">确认删除</h4>
          </div>
          <div class="modal-body" style="text-align: center;">
            <p style="color: #ff6565"><b>确认要删除记录？</b></p>
          </div>
          <div class="modal-footer">
            <span style="color: #ff6565;font-size: 15px;margin-right: 10px">{{systemInfo}}</span>
            <button type="button" class="btn btn-default" data-dismiss="modal">关闭</button>
            <button type="button" class="btn btn-primary" @click="remove(select.id)">确认</button>
            <br />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import Cookies from "js-cookie";
import GLOBAL from "@/global_variable";
import JSEncrypt from 'jsencrypt';

export default {
  name: 'account',
  data: function() {
    return {
      type: [],
      data: [],
      mode: 'add',
      select: {
        id: null,
        site: null,
        username: null,
        oldPassword: null,
        password: null,
        passwordConfirm: null,
        type: '1',
      },
      recordType: null,
      queryParam: {
        token: null,
        ma: null
      },
      login: false,
      result: null,
      maKey: null,
      maKeyInterval: 0,
      disabled: '',
      systemInfo: '',

      newType: {
        name: null
      }
    }
  },
  methods: {
    getType() {
      if (window.localStorage.getItem('accessToken')) {
        this.data = [];
        this.$http.get(GLOBAL.apiUrl + "/account/list").then(response => {
          const res = response['data'];
          if (res['code'] === 0) {
            this.type = res['data'];
            if (this.type.length > 0) {
              this.getData(this.type[0].id)
            }
            this.login = true;
            this.systemInfo = '';
          } else {
            this.systemInfo = res['message'];
          }
        }, err => {
          this.systemInfo = err['body']['message'];
        })
      } else {
        this.login = false;
      }
    },
    getData(type) {
      if (this.recordType == type) {
        this.recordType = null;
      } else {
        this.recordType = type;
        if (window.localStorage.getItem('accessToken')) {
          this.$http.get(GLOBAL.apiUrl + "/account/list/" + type).then(response => {
            const res = response['data'];
            if (res['code'] === 0) {
              this.data = res['data'];
              this.login = true;
              this.systemInfo = '';
            } else {
              this.systemInfo = res['message'];
            }
          }, err => {
            this.systemInfo = err['body']['message'];
          })
        } else {
          this.login = false;
        }
      }
    },
    openAddTypeDialog() {
      this.systemInfo = '';
      this.$("#addTypeModal").modal();
    },
    openAddDialog() {
      this.systemInfo = '';
      this.$("#addModal").modal();
    },
    openRemoveDialog(item) {
      this.systemInfo = '';
      this.select = item;
      this.$("#removeModal").modal();
    },
    addType() {
      if (window.localStorage.getItem('accessToken')) {
        if (this.newType.name === null || this.newType.name === '') {
          this.systemInfo = "分类名称不能为空";
          return;
        }
        this.$http.post(GLOBAL.apiUrl + "/account/addType", this.newType).then(response => {
          const res = response['data'];
          if (res['code'] === 0) {
            this.newType.name = null;
            this.$('#addTypeModal').modal('hide')
            this.getType();
          } else {
            this.systemInfo = res['message'];
          }
        }, err => {
          this.systemInfo = err['body']['message'];
        });
      } else {
        this.login = false;
      }
    },
    add() {
      if (window.localStorage.getItem('accessToken')) {
        if (this.recordType === null || this.recordType === '') {
          this.systemInfo = "未知错误，请刷新重试";
          return;
        }
        if (this.select.site === null || this.select.site === '') {
          this.systemInfo = "网站不能为空";
          return;
        }
        if (this.select.username === null || this.select.username === '') {
          this.systemInfo = "用户名不能为空";
          return;
        }
        if (this.mode === 'edit' && (this.select.oldPassword === null || this.select.oldPassword === '')) {
          this.systemInfo = "旧密码不能为空";
          return;
        }
        if (this.select.password === null || this.select.password === '') {
          this.systemInfo = "密码不能为空";
          return;
        }
        if (this.select.password !== this.select.passwordConfirm) {
          this.systemInfo = "两次密码不一样";
          return;
        }
        this.systemInfo = '';
        const jse = new JSEncrypt();
        jse.setPublicKey('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCN6W0FeTVybtIBuhT+28ccc5gCuncZ6s2RCql/Gyk4ncjCn+4VNdF8rRraGE9Lb+sHcaA/1pPgHDCYPYmoo2XFBW8yihpp/YL4K4/ckdhwcE7T+opd+CuqPswFmJS2FynXxBD5jEHcCvyTWFG/Z5Er2OezeMUSUUO80L03jSkwjwIDAQAB')
        this.$http.post(GLOBAL.apiUrl + "/account/" + this.mode, {
          id: this.select.id,
          site: this.select.site,
          username: this.select.username,
          oldPassword: jse.encrypt(this.select.oldPassword),
          password: jse.encrypt(this.select.password),
          type: this.select.type,
          recordType: this.recordType
        }).then(response => {
          const res = response['data'];
          if (res['code'] === 0) {
            this.getData(this.recordType)
            this.$('#addModal').modal('hide')
          } else {
            this.systemInfo = res['message'];
          }
        }, err => {
          this.systemInfo = err['body']['message'];
        });
      } else {
        this.login = false;
      }
    },
    remove(id) {
      if (window.localStorage.getItem('accessToken')) {
        this.$http.post(GLOBAL.apiUrl + "/account/invalid/" + id).then(response => {
          const res = response['data'];
          if (res['code'] === 0) {
            this.$("#removeModal").modal('hide');
            this.getData()
          } else {
            this.systemInfo = res['message'];
          }
        }, err => {
          this.systemInfo = err['body']['message'];
        });
      } else {
        this.login = false;
      }
    },
    resetSelect() {
      this.select = {
        site: null,
        username: null,
        oldPassword: null,
        password: null,
        passwordConfirm: null,
        type: 1
      }
    },
    resetQuery() {
      this.queryParam = {
        token: null,
        ma: null
      };
      this.maKey = null;
    },
    query(id) {
      this.systemInfo = '';
      if (window.localStorage.getItem('accessToken')) {
        if (this.select.type !== 1) {
          this.$("#queryModal").modal();
          return;
        }
        this.$http.get(GLOBAL.apiUrl + "/account/query", {params: {
            id: id
          }}).then(response => {
          const res = response['data'];
          if (res['code'] === 0) {
            this.result = res['data'];
            this.$("#passwordModal").modal();
          } else {
            this.systemInfo = res['message'];
          }
        }, err => {
          this.systemInfo = err['body']['message'];
        })
      } else {
        this.login = false;
      }
    },
    query2(id) {
      if (window.localStorage.getItem('accessToken')) {
        if (this.queryParam.token === null || this.queryParam.token === '') {
          this.systemInfo = '口令不能为空';
          return;
        }
        if (this.select.type === 3 && (this.queryParam.ma === null || this.queryParam.ma === '')) {
          this.systemInfo = '验证码不能为空';
          return;
        }
        this.systemInfo = '';
        const jse = new JSEncrypt();
        jse.setPublicKey('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCN6W0FeTVybtIBuhT+28ccc5gCuncZ6s2RCql/Gyk4ncjCn+4VNdF8rRraGE9Lb+sHcaA/1pPgHDCYPYmoo2XFBW8yihpp/YL4K4/ckdhwcE7T+opd+CuqPswFmJS2FynXxBD5jEHcCvyTWFG/Z5Er2OezeMUSUUO80L03jSkwjwIDAQAB')
        this.$http.get(GLOBAL.apiUrl + "/account/query", {params: {
            id: id,
            token: jse.encrypt(this.queryParam.token),
            key: this.maKey,
            ma: this.queryParam.ma,
          }}).then(response => {
          const res = response['data'];
          if (res['code'] === 0) {
            this.result = res['data'];
            this.$("#queryModal").modal('hide');
            this.$("#passwordModal").modal();
            this.resetQuery();
          } else {
            this.systemInfo = res['message'];
          }
        }, err => {
          this.systemInfo = err['body']['message'];
        })
      } else {
        this.login = false;
      }
    },
    getMa() {
      this.disabled = 'disabled'
      this.maKeyInterval = 60
      let timer = setInterval(() => {
        if (this.maKeyInterval-- === 0) {
          clearInterval(timer)
          this.disabled = ''
        }
      }, 1000)
      this.maKey = Math.floor(Math.random() * (900000)) + 100000
      this.$http.get(GLOBAL.apiUrl + "/account/ma", {params: {
          key: this.maKey,
        }}).then(() => {
      }, err => {
        this.systemInfo = err['body']['message'];
      })
    }
  },
  created() {
    this.getType();
    this.$('#accordion').on('show.bs.collapse', event => {
      console.log(event)
    })
  }
}
</script>

<style>
.glyphicon:hover{
  cursor: pointer;
  border-bottom: 1px solid #5e5e5e;
}
.checkbox-inline+.checkbox-inline, .radio-inline+.radio-inline{
  margin-left: 0;
}
</style>
