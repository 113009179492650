<template>
  <div class="vlogs">
    <div v-if="isShow" class="top">
      <button type="button" class="btn btn-primary" @click="writeVlog">
        <span class="glyphicon glyphicon-pencil"></span>
      </button>
    </div>
    <section class="outVlog">
      <div class="vlogList col-sm-12 col-md-8 col-xs-12">
        <section v-for="item in vlogLists" :key="item.id" class="part">
          <div style="min-width: 60px; color: #618fb7;font-size: 15px"><b>{{ item.creator }}:</b></div>
          <div style="width: calc(100% - 60px)">
            <section class="title" style="white-space: pre-wrap">{{ item.message ? item.message : '' }}
              <div v-if="isShow" style="color: #618fb7; min-width: 30px; margin-left: 20px" @click="deleteVlog(item)">删除</div>
            </section>
            <section class="content">
              <div v-for="(o, i) in item.photos" :key="i" style="margin: 0 5px 5px 0; position:relative; display: inline-block;">
                <section v-if="o.type == 1" class="photoInner" :style="{width: (item.photos.length == 1? 'calc(100vw / 2)' : item.photos.length == 2? 'calc(100vw / 3)' : 'calc(100vw / 4.5)')}">
                  <div style="margin-top: 100%;"></div>
                  <div style="position:absolute; left:0; right:0; top:0; bottom: 0">
                    <img :src="apiUrl + '/file/image/' + o.thumbnail" alt="" srcset="" style="height: 100%; object-fit: cover;" width="100%" @click="showimage(apiUrl + '/file/image/' + o.uuid)">
                  </div>
                </section>
                <video v-if="o.type == 2" controls style="max-width: 95%;max-height: 400px;">
                  <source :src="apiUrl + '/file/player/' + o.uuid" type="video/mp4">
                  <source :src="apiUrl + '/file/player/' + o.uuid" type="video/ogg">
                  <source :src="apiUrl + '/file/player/' + o.uuid" type="video/webm">
                </video>
              </div>
            </section>
            <div style="display: flex;margin-top: 10px">
              <section style="font-size: 10px; color: #999; ">{{ item.createTime }}</section>
              <span class="glyphicon glyphicon-heart" style="margin-left: 10px" @click="heart(item)"></span>
              <span style="margin-top: -2px;margin-left: 3px">{{item.heart}}</span>
            </div>
          </div>
        </section>
      </div>
    </section>
    <!-- Modal -->
    <div class="modal fade" id="setoutModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" data-backdrop="static">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="myModalLabel">心情随笔</h4>
          </div>
          <div class="modal-body">
            <form class="form-inline">
              <div class="form-group">
                <label for="contents" style="margin-right: 10px">内容：</label>
                <textarea class="form-control" id="contents" v-model="contents" placeholder="请输入内容" />
              </div>
              <div>
                <label for="contents" style="margin-right: 10px">是否私密：</label>
                <input type="checkbox" v-model="selectSec">
              </div>
              <div v-show="isShow" class="uploadInput">
                <input type="file" name="file" id="photo_file" multiple class="file-loading" />
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
            <button type="button" class="btn btn-primary" v-on:click="setout()">发布</button>
            <br />
          </div>
        </div>
      </div>
    </div>

    <!-- 图片放大 -->
    <div id="ShowImage_Vlog" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false">         
      <div class="modal-dialog" role="document" style="width: 100%;margin: 0">
        <!-- <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div> -->
        <div class="modal-body col-sm-12 col-md-8 col-md-offset-2">
          <div id="img_show">
            <img :src='source' class='carousel-inner img-responsive img-rounded' @click='closeModal' />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GLOBAL from '../global_variable';
// import Cookies from 'js-cookie';

export default {
  name: 'vlog',
  data() {
    return {
      contents: '',
      vlogLists: [],
      isShow: false,
      selectSec: false,
      succUpload: [],
      succUUid: [],
      source: '',
      apiUrl: GLOBAL.apiUrl,
      pageLimit: 10,
      pageOffset: 0
    }
  },
  mounted() {
    this.setUpload()
    this.deleteUpload()
    if (window.localStorage.getItem('refreshToken')) {
      this.isShow = true
    }
    let tempLimit = this.pageLimit
    if (window.localStorage.getItem("vlogPageLimit")) {
      const o = Number(window.localStorage.getItem("vlogPageLimit"));
      window.localStorage.removeItem("vlogPageLimit")
      tempLimit = (isNaN(o) ? 0 : o);
    }
    this.getVlogs(tempLimit, 0)
    this.pageOffset = tempLimit
    this.srollWitch = false;
    this.scrollLoad()
    this.refresh()
  },
  methods: {
    setUpload() {
      this.$('#photo_file').fileinput({
        uploadUrl: GLOBAL.apiUrl + "/file/upload", // 服务器接收上传文件的方法
        uploadExtraData: {
          path: '/vlog'
        },
        ajaxSettings: {
          headers: {
            Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
          }
        },
        maxFileCount: 10,//上传最大文件数
        uploadAsync: false, //默认异步上传
        // showRemove: true, //显示移除标题
        // showCaption: true, //是否显示标题
        // showPreview: true, //是否显示预览
        showUpload:false,//是否显示上传按钮
        dropZoneEnabled: true,//是否显示拖拽区域，会占用很大区域
        overwriteInitial:false,//是否要覆盖初始预览内容和标题设置
        language: 'zh', //语言
        initialPreviewAsData: true, //是否将初始预览内容集解析为数据而不是原始标记语言
        purifyHtml: true, //是否在预览中净化HTML内容类型显示的HTML内容
        maxFileSize:1024*30, //最大上传大小30M
        allowedFileExtensions: ['jpg','png','bmp','jpeg', 'mp4'],//限制文件的后缀名
        // allowedFileTypes: ['image', 'html', 'text', 'video', 'audio', 'flash', 'object'],//限制文件的类型
        // allowedFileTypes: ['image','text'],//allowedFileExtensions和allowedFileTypes 只能二选一
        // layoutTemplates :{
        //   actionUpload: false,//去掉缩阅图中的上传按钮
        //   actionDelete: false//去掉缩略图中的删除按钮
        // }
      }).on("filebatchselected", () => {
        // 选择文件后立即触发上传方法
        this.$('#photo_file').fileinput("upload")
      }).on("filebatchuploadsuccess", (event, data) => {
        if (data.response.code === 0) {
          this.succUpload = this.succUpload.concat(data.response.data)
          this.succUUid = [];
          this.succUpload.forEach(item => {
            this.succUUid.push(item.uuid)
          })
          this.succUpload = []
        }
      });
    },
    deleteUpload() {
      this.$("#photo_file").on("filepredelete", function() {
        var abort = true;
        if (confirm("是否删除该图片?")) {
            abort = false;
        }
        return abort; //
      });
    },
    getVlogs(limit = 0, offset = 0, addition = true) {
      this.$http.get(GLOBAL.apiUrl + "/record/list", {params: {count:0, limit: limit, offset: offset}}).then(res => {
        if (res.data.code === 0) {
          if (addition) {
            this.vlogLists = this.vlogLists.concat(res.data.data.data)
          } else {
            this.vlogLists = res.data.data.data
          }
        }
        if (res.data.data.data.length >= this.pageLimit) {
          this.srollWitch = true;
        }
      })
    },
    writeVlog() {
      this.$('#setoutModal').modal()
    },
    setout() {
      this.$http.post(GLOBAL.apiUrl + "/record/add", { message: this.contents, secret: this.selectSec? 1 : 0, photos: this.succUUid }).then(res => {
        if (res.data.code === 0) {
          this.getVlogs(this.pageLimit, this.offset(0), false)
          this.contents = ''
          this.selectSec = false
          this.succUpload = []
        }
      })
      this.$('#setoutModal').modal('hide')
    },
    deleteVlog(val) {
      this.$http.post(GLOBAL.apiUrl + "/record/remove/" + val.id).then(res => {
        if (res.data.code === 0) {
          this.getVlogs(this.pageOffset, 0, false)
        }
      })
    },
    showimage(source) {
      this.source = source
      // var $modal = this.$('#ShowImage_Vlog');

      // // 测试 bootstrap 居中
      // $modal.on('show.bs.modal', () => {
      //   var $modal_dialog = $modal.find('.modal-dialog');
      //   // 关键代码，如没将modal设置为 block，则$modala_dialog.height() 为零
      //   $modal.css('display', 'block');
      //   $modal_dialog.css({'margin-top': Math.max(0, (this.$(window).height() - $modal_dialog.height()) / 2) });
      // });
      this.$("#ShowImage_Vlog").modal();
    },
    closeModal() {
      this.$("#ShowImage_Vlog").modal('hide');
    },

    scrollLoad() {
      window.addEventListener('scroll', () => {
        const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        if (clientHeight + scrollTop + 500 >= scrollHeight) {
          if (this.srollWitch) {
            this.srollWitch = false;
            this.getVlogs(this.pageLimit, this.offset());
          }
        }
      })
    },
    refresh() {
      window.addEventListener('beforeunload', () => {
        // window.localStorage.setItem("vlogPageLimit", this.pageOffset);
        document.documentElement.scrollTop = 0
      })
    },
    offset(start) {
      if (start !== undefined) {
        this.pageOffset = start
      }
      const t = this.pageOffset
      this.pageOffset = this.pageLimit + this.pageOffset
      return t
    },
    heart(item) {
      this.$http.post(GLOBAL.apiUrl + "/record/heart/" + item.id).then(res => {
        if (res.data.code === 0) {
          item.heart++
        }
      })
    }
  }
}
</script>

<style scoped>
.vlogs {
  padding: 0 0px;
}
.modal-content {
  width: 100%;
}
.part {
  display: flex;
  margin-bottom: 15px;
  width: 100%;
}
.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.outVlog {
  display: flex;
  justify-content: center;
}
.vlogList {
  /* width: 100%; */
}
.top {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.title {
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.photoInner {
  max-width: 250px;
}
.glyphicon-heart:hover{
  color: #ff6565;
}
</style>