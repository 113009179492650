import Vue from 'vue'
import App from './App.vue'
import $ from 'jquery'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap-fileinput/css/fileinput.min.css'
import 'bootstrap-fileinput/js/fileinput.min.js'
// import VueRouter from 'vue-router'
import router from './routers'
import VueResource from 'vue-resource'
// import Cookies from 'js-cookie';
import GLOBAL from "@/global_variable";

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

// Vue.use(VueRouter)
// const router = new VueRouter({
//   mode: 'history',
//   routes: routers
// })

Vue.use(VueResource)
Vue.http.interceptors.push((request, next) => {
  const accessToken = window.localStorage.getItem('accessToken');
  const refreshToken = window.localStorage.getItem('refreshToken');
  if (accessToken) {
    request.headers.set("Authorization", "Bearer " + accessToken);
  }

  const NRecordkey = localStorage.getItem('no_record_day');
  if (NRecordkey !== null) {
      request.headers.set("Origin-NRecord-Key", NRecordkey);
  }

  let visKey = sessionStorage.getItem('key');
  if (visKey === null && request.url !== GLOBAL.apiUrl + '/key') {
      Vue.http.get(GLOBAL.apiUrl + "/key").then(response => {
        const res = response['data'] || {code: 1, message: response['message']};
        if (res['code'] > 0) {
            return {
                message: '请求错误，刷新页面后重试'
            }
        } else {
            visKey = res['data']
            sessionStorage.setItem('key', visKey)
            request.headers.set("Origin-Vis-Key", visKey);
            nextResponse(request, next)
        }
      })
  } else {
      request.headers.set("Origin-Vis-Key", visKey);
      nextResponse(request, next, refreshToken)
  }
})

Vue.config.productionTip = false
Vue.prototype.$ = $

function nextResponse(request, next, refreshToken) {
    next(async response => {
        if (response.status === 401) {
            if (response.url === GLOBAL.apiUrl + '/refresh') {
                window.localStorage.removeItem('accessToken');
                window.localStorage.removeItem('refreshToken');
                response['data']['message'] = '登录信息已失效';
                return response;
            }
            if (refreshToken) {
                response = await Vue.http.post(GLOBAL.apiUrl + '/refresh', {refresh_token: refreshToken}).then(async response => {
                    const res = response['data'];
                    if (res['code'] > 0) {
                        window.localStorage.removeItem('accessToken');
                        window.localStorage.removeItem('refreshToken');
                        return response;
                    } else {
                        window.localStorage.setItem('accessToken', res['data']['accessToken']);
                        window.localStorage.setItem('refreshToken', res['data']['refreshToken']);
                        return await Vue.http[request.method.toLowerCase()](request.url, request.params).then(async response => {
                            return response;
                        }, error => {
                            return error;
                        });
                    }
                }, err => {
                    window.localStorage.removeItem('accessToken');
                    window.localStorage.removeItem('refreshToken');
                    return err;
                })
            } else {
                window.localStorage.removeItem('accessToken');
                window.localStorage.removeItem('refreshToken');
                response['data']['message'] = '登录信息已失效';
                return response;
            }
        }
        if (response.status === 403) {
            console.log(403)
        }
        return response;
    })
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


