<template>
  <div class="markdown-body" style="padding: 5px 0px 0 0px">

    <div class="col-sm-3 col-md-3 col-lg-2 col-lg-offset-1 list-group" :style="{cursor: 'pointer', height: sHeight, overflowY: 'scroll', padding: '0 0 5px 0'}">
      <a v-for="item in catalogue" :key="item.id" class="list-group-item" v-on:click="scrollTop(item.href,100)">{{item.name}}</a>
    </div>
    <div class="col-sm-9 col-md-9 col-lg-9" :style="{height: sHeight, overflowY: 'scroll', padding: '0 15px 5px 15px'}">
      <vue-markdown toc=true :source="message"></vue-markdown>
    </div>

  </div>
</template>

<script>
import vueMarkdown from 'vue-markdown';
import 'github-markdown-css/github-markdown.css';
import GLOBAL from '../global_variable';

export default {
  name: 'blog',
  props: {
    folder: String,
    fileName: String
  },
  components: {
    vueMarkdown
  },
  data:function() {
    return {
      message: null,
      catalogue: [],
      sHeight: '100px',
    }
  },
  methods: {
    loadFile() {
      const xhr = new XMLHttpRequest();
      const status = document.location.protocol === 'file' ? 0 : 200;

      xhr.open('GET', GLOBAL.apiUrl + "/blog/show?typeCode=" + this.folder + "&name=" + this.fileName, false);
      xhr.setRequestHeader("Origin-Vis-Key", sessionStorage.getItem('key'))
      xhr.setRequestHeader("Origin-NRecord-Key", localStorage.getItem('no_record_day'));
      xhr.overrideMimeType('test/html;charset=-utf-8');
      xhr.send(null);
      // this.$('html,body').animate({scrollTop:0},1000);
      // document.documentElement.scrollTop = 0;
      // document.body.scrollTop = 0;
      this.message = xhr.status === status ? (xhr.responseText === null ? null : xhr.responseText
          .replaceAll('](images/', '](' + GLOBAL.apiUrl + '/blog/' + this.folder + '/images/')
          .replaceAll('<img src="images/', '<img src="' + GLOBAL.apiUrl + '/blog/' + this.folder + '/images/')
          ) : null;

      let tempMessage = this.message.replaceAll(new RegExp(/(```).+[\n|\r\n][^```]*(```)/g), "");
      let result = tempMessage.match(new RegExp(/#+\s.+[\n|\r\n]/g));
      if (result != null && result.length > 0) {
        let index = 0;
        result = result.map(item => {
          let s = item.trim().split(" ");
          let result = "";
          for (let i = 0; i < s[0].length - 1; i++) {
            result += "\xa0\xa0\xa0\xa0";
          }
          s.splice(0, 1);
          for (let i = 0; i < s.length; i++) {
            s[i] = s[i].replaceAll("**", "")
          }
          const name = result + s.join(' ');


          for (let i = 0; i < s.length; i++) {
            s[i] = s[i].replaceAll('.', '').toLowerCase()
          }
          const href = '#' + s.join('-');

          return {
            id: index++,
            name,
            href
          }
        })
      } else {
        result = [];
      }
      this.catalogue = result;
    },
    scrollTop(href) {
      location.href = href;
      document.documentElement.scrollTop = document.documentElement.scrollTop - 100;
    }
  },
  created() {
      this.loadFile()
      this.sHeight = window.screen.height-270 + 'px'
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
