<template>
  <div class="photos">
    <div class="photosMain">
      <div v-show="isShow" class="uploadInput">
        <input type="file" name="file" id="photo_file" multiple class="file-loading" />
      </div>
      <div class="col-sm-6 col-md-2 col-xs-6" v-for="item in photoList" :key="item.id">
        <div class="thumbnail" style="margin-bottom: 32px">
          <img :src="apiUrl + '/file/image/' + item.thumbnail" alt="" width="100%" style="min-height: 100px" @click="showimage(apiUrl + '/file/image/' + item.uuid)">
          <div style="display: flex; justify-content: space-between; align-items: center">
            <div><span class="glyphicon glyphicon-heart" style="margin-top: 5px" @click="heart(item)"></span> {{item.heart}}</div>
            <span v-if="isShow" class="glyphicon glyphicon-backward" style="margin-top: 5px;" @click="orderUp(item)"></span>
            <input v-if="isShow" type="number" class="form-control input-sm" :id="'orderNum_' + item.id" value="1" style="width: 60px;height: 20px;margin-top: 5px;"/>
            <span v-if="isShow" class="glyphicon glyphicon-forward" style="margin-top: 5px;" @click="orderDown(item)"></span>
            <span v-if="isShow" class="glyphicon glyphicon-trash" style="margin-top: 5px;" @click="deletePho(item)"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="infoModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content" style="border-radius: 25px">
          <div class="modal-body" style="text-align: center;background-color: red;border-radius: 25px">
            <span style="color: white;font-size: 15px">{{systemInfo}}</span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
        </div>
      </div>
    </div>

    <!-- 图片放大 -->
    <div class="modal fade" id="ShowImage_Form" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document" style="width: 100%;margin: 0">
        <!--<div class="modal-content">-->
         <!--<div class="modal-header">-->
            <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
          <!--</div>-->
          <div class="modal-body col-sm-12 col-md-8 col-md-offset-2">
            <div id="img_show">
              <img :src='source' class='carousel-inner img-responsive img-rounded' style="width: 100%;" @click='closeModal' />
            </div>
          </div>
        <!--</div>-->
      </div>
    </div>
  </div>
</template>

<script>
// import Cookies from 'js-cookie';
import GLOBAL from '../global_variable';

export default {
  name: 'photos',
  data() {
    return {
      oFileInput: null,
      isShow: false,
      photoList: [],
      systemInfo: '',
      name: '',
      description: '',
      selectSec: true,
      succUpload: [],
      source: '',
      apiUrl: GLOBAL.apiUrl
    }
  },
  mounted() {
    this.setUpload()
    this.deleteUpload()
    if (window.localStorage.getItem('refreshToken')) {
      this.isShow = true
    }
    this.getPhotos()
  },
  methods: {
    setUpload() {
      this.$('#photo_file').fileinput({
        uploadUrl: GLOBAL.apiUrl + "/file/upload", // 服务器接收上传文件的方法
        uploadExtraData: {
          path: '/photo'
        },
        ajaxSettings: {
          headers: {
            Authorization: 'Bearer ' + window.localStorage.getItem('accessToken'),
          }
        },
        maxFileCount: 10,//上传最大文件数
        uploadAsync: false, //默认异步上传
        // showRemove: true, //显示移除标题
        // showCaption: true, //是否显示标题
        // showPreview: true, //是否显示预览
        showUpload:false,//是否显示上传按钮
        dropZoneEnabled: true,//是否显示拖拽区域，会占用很大区域
        overwriteInitial:false,//是否要覆盖初始预览内容和标题设置
        language: 'zh', //语言
        initialPreviewAsData: true, //是否将初始预览内容集解析为数据而不是原始标记语言
        purifyHtml: true, //是否在预览中净化HTML内容类型显示的HTML内容
        maxFileSize:1024*30, //最大上传大小10M
        allowedFileExtensions: ['jpg','png','bmp','jpeg'],//限制文件的后缀名
        // allowedFileTypes: ['image', 'html', 'text', 'video', 'audio', 'flash', 'object'],//限制文件的类型
        // allowedFileTypes: ['image','text'],//allowedFileExtensions和allowedFileTypes 只能二选一
        // layoutTemplates :{
        //   actionUpload: false,//去掉缩阅图中的上传按钮
        //   actionDelete: false//去掉缩略图中的删除按钮
        // }
      }).on("filebatchselected", () => {
        // 选择文件后立即触发上传方法
        this.$('#photo_file').fileinput("upload")
      }).on("filebatchuploadsuccess", (event, data) => {
        if (data.response.code === 0) {
          this.succUpload = this.succUpload.concat(data.response.data)
          this.addPho()
          this.succUpload = []
        }
      });
    },
    deleteUpload() {
      this.$("#photo_file").on("filepredelete", function() {
        var abort = true;
        if (confirm("是否删除该图片?")) {
            abort = false;
        }
        return abort; //
      });
    },
    getPhotos() {
      this.$http.get(GLOBAL.apiUrl + "/photo/" + this.$route.query.id).then(res => {
        if (res.data.code === 0) {
          this.photoList = res.data.data
        }
      })
    },
    addPho() {
      let params = [];
      this.succUpload.forEach(item => {
        params.push({ albumId: this.$route.query.id, uuid: item.uuid })
      })
      this.$http.post(GLOBAL.apiUrl + "/photo/add", params).then(response => {
          const res = response['data'];
          if (res['code'] > 0) {
            this.systemInfo = res['message'];
            this.$('#infoModal').modal()
          } else {
            this.getPhotos();
            this.name = '';
            this.description = '';
          }
        }, err => {
            this.systemInfo = err['data']['message'];
            this.$('#infoModal').modal()
        });
    },
    deletePho(item) {
      this.$http.post(GLOBAL.apiUrl + "/photo/remove/" + this.$route.query.id, [item.uuid]).then(res => {
        if (res.data.code === 0) {
          this.getPhotos()
        }
      })
    },
    orderUp(item) {
      const num = Number(this.$('#orderNum_' + item.id).val())
      if (num < 0) {
        this.$('#orderNum_' + item.id).val(1)
        return;
      }
      this.$http.post(GLOBAL.apiUrl + "/photo/order/up", {
        albumId: item.albumId,
        photoId: item.id,
        order: item.order,
        num: num
      }).then(res => {
        if (res.data.code === 0) {
          this.getPhotos()
          this.$('#orderNum_' + item.id).val(1)
        }
      })
    },
    orderDown(item) {
      const num = Number(this.$('#orderNum_' + item.id).val())
      if (num < 0) {
        this.$('#orderNum_' + item.id).val(1)
        return;
      }
      this.$http.post(GLOBAL.apiUrl + "/photo/order/down", {
        albumId: item.albumId,
        photoId: item.id,
        order: item.order,
        num: num
      }).then(res => {
        if (res.data.code === 0) {
          this.getPhotos()
          this.$('#orderNum_' + item.id).val(1)
        }
      })
    },
    heart(item) {
      this.$http.post(GLOBAL.apiUrl + "/photo/heart/" + item.id).then(res => {
        if (res.data.code === 0) {
          item.heart++
        }
      })
    },
    showimage(source) {
      this.source = source;
      // var $modal = this.$('#ShowImage_Form');

      // // 测试 bootstrap 居中
      // $modal.on('show.bs.modal', () => {
      //   var $modal_dialog = $modal.find('.modal-dialog');
      //   // 关键代码，如没将modal设置为 block，则$modala_dialog.height() 为零
      //   $modal.css('display', 'block');
      //   $modal_dialog.css({'margin-top': Math.max(0, (this.$(window).height() - $modal_dialog.height()) / 2) });
      // });
      this.$("#ShowImage_Form").modal();
    },
    closeModal() {
      this.$("#ShowImage_Form").modal('hide');
    }
  }
}
</script>

<style scoped>
.uploadInput {
  margin: 0 15px 10px 15px;
}
.photos{
  width: 100%;
  height: calc(100vh - 100px);
  overflow-y: auto;
  padding: 0 15px;
}
.caption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999;
  font-size: 12px;
}
.addPho {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 10px 0;
}
.photoPho {
  opacity: 0.9;
}
.photoDes {
  position: absolute;
  top: 35%;
  left: 10%;
  color: white;
  font-size: 18px;
  font-weight: 800;;
  z-index: 1000;
}
  .glyphicon-heart:hover{
    color: #ff6565;
  }
</style>