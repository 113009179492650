import BolgList from './components/blogList'
import Bolg from './components/blog'
import Home from './components/home'
// import Building from './components/building'
import Message from './components/message'
import MessageApproval from './components/messageApproval'
import Vlog from './components/vlog'
import Photos from './components/photos'
import PhotoInner from './components/photosInner'
import Visitor from './components/visitor'
import Translate from './components/translate'
import Bill from './components/bill'
import Account from './components/account'
import Tool from './components/tool';
import ScanCodePage from './components/scanCodePage';
import ScanCodeResult from './components/scanCodeResult';

import Router from "vue-router";
import Vue from 'vue';

Vue.use(Router);

const routes = [
    {
        path: '/',
        component: Home
    },
    {
        path: '/home',
        component: Home
    },
    {
        path: '/mood',
        component: Vlog
    },
    {
        path: '/blog/list/:folder',
        component: BolgList,
        props: true
    },
    {
        path: '/blog/:folder/:fileName',
        component: Bolg,
        props: true
    },
    {
        path: '/photo',
        component: Photos
    },
    {
        path: '/photoInner',
        component: PhotoInner
    },
    {
        path: '/message',
        component: Message
    },
    {
        path: '/message/approval',
        component: MessageApproval
    },
    {
        path: '/visitor',
        component: Visitor
    },
    {
        path: '/account',
        component: Account
    },
    {
        path: '/translate',
        component: Translate
    },
    {
        path: '/bill',
        component: Bill
    },
    {
        path: '/tool',
        component: Tool
    },
    {
        path: '/scanCodePage',
        component: ScanCodePage
    },
    {
        path: '/scanCodeResult/:code',
        component: ScanCodeResult,
        props: true
    }
]

const router = new Router({
    routes,
});

export default router
