<template>
  <div>
    <div style="text-align: center">
      <h2 style="text-shadow: 1px 1px 2px #999;">元元和凯莉</h2>
      <h2 style="text-shadow: 1px 1px 2px #999;">--YYHKL--</h2>
      <div class="col-md-5" style="margin: auto;float: none;">
        <h2 style="position: absolute; left: 40px; color: #fff; text-shadow: 1px 1px 2px #fff;"></h2>
        <img class="img-rounded img-responsive" src="../../public/images/background.jpg" style="box-shadow:0px 2px 10px #999999;border-radius: 10px" />
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'home',

}
</script>
