<template>
  <div class="message">
    <div class="notice">
      <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true" style="color: #ff6565; font-size: 12px;  margin-right: 5px"></span>
      <span style="color: #ff6565; font-size: 14px"><b>为保障良好的网络环境，您的留言需要经过后台人工审核才能发送成功，我们会尽快为您审核！</b></span>
    </div>
    <div class="panel panel-primary">
      <!-- <div class="panel-heading">
        <span class="panel-title">{{ message }}</span>
      </div> -->
      <div ref="messPanel" class="panel-body">
        <section>
          <div class="messOut" v-for="(item, index) in messList" :key="index">
            <span class="messName">{{ item.name }}</span>
            :
            <div :class="['messInner', { 'secretCon': item.secret }]">
              <span class="glyphicon glyphicon-lock" v-if="item.secret" aria-hidden="true"></span> {{ item.content }}
            </div>
            <span class="messTime">{{ item.createTime }}</span>
            <br /><span v-if="item.ipAddr !== null" style="font-size: 12px;color: #999;">{{item.ip}} - {{item.ipAddr}}</span>
          </div>
        </section>
      </div>
    </div>
    <div class="messInputFix">
      <span class="messPre" v-if="currentName || messValue || (alertShow && (!currentName || !messValue || messValue.length > 300)) || (currentName.length > 50 && currentName) || (messValue && messValue.length > 300)">
        <span class="messPre" v-if="currentName || messValue">{{ currentName }}: {{ messValue }}</span>
        <span class="messPre" v-if="alertShow && !currentName" style="color: #ff8a8f">请输入昵称：</span>
        <span class="messPre" v-if="alertShow && (!messValue || messValue.length > 300)" style="color: #ff8a8f">请输入留言</span><br>
        <span class="messPre" v-if="currentName.length > 50 && currentName" style="color: #ff8a8f">昵称写这么老长，50个字差不多得了</span><br>
        <span class="messPre" v-if="messValue && messValue.length > 300" style="color: #ff8a8f">留言太长了，写短点吧 300字就行了</span>
      </span>
      <section class="inputMess">
        <input type="text" style="width: 20%" class="form-control" placeholder="昵称" v-model.trim="currentName">&nbsp;:&nbsp;
        <div class="input-group" style="width: 80%">
          <span class="input-group-addon">
            <input type="checkbox" v-model="selectSec">
          </span>
          <input type="text" class="form-control" placeholder="请输入留言" aria-describedby="basic-addon2" v-model.trim="messValue">
          <span class="input-group-addon" id="basic-addon2" @click="submit" style="background: #337ab7; color: #fff"> 确 认 </span>
        </div>
      </section>
      <span class="tipInput">勾选后将私密发送，未登录用户不可见</span>
    </div>

    <div class="modal fade" id="infoModalMess" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content" style="border-radius: 25px">
          <div class="modal-body" style="text-align: center; background-color: #618fb7; border-radius: 25px">
            <span style="color: white;font-size: 14px">{{systemInfo}}</span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from 'moment';
// import Cookies from 'js-cookie';
import GLOBAL from '../global_variable';

export default {
  data() {
    return {
      message: '留言板',
      messValue: '',
      currentName: window.localStorage.getItem('messName') || '',
      alertShow: false,
      messList: [],
      selectSec: false,
      systemInfo: '',
      pageLimit: 20,
      pageOffset: 0
    }
  },
  mounted() {
    let tempLimit = this.pageLimit
    if (window.localStorage.getItem("messPageLimit")) {
      const o = Number(window.localStorage.getItem("messPageLimit"));
      window.localStorage.removeItem("messPageLimit")
      tempLimit = (isNaN(o) ? 0 : o);
    }
    this._getMessageList(tempLimit, 0);
    this.pageOffset = tempLimit
    this.srollWitch = false;
    this.scrollLoad()
    this.refresh()
  },
  methods: {
    submit() {
      this.alertShow = false
      if (this.messValue && this.currentName) {
        if (this.messValue.length > 300 || this.currentName.length > 50) {
          // 超长了
        } else {
          let params = {
            name: this.currentName,
            content: this.messValue,
            secret: this.selectSec,
          }
          window.localStorage.setItem('messName', this.currentName)
          this.$http.post(GLOBAL.apiUrl + "/message/add", params).then(res => {
            if (res.data.code === 0) {
              this.systemInfo = '发送成功，请耐心等待审核';
              this.$('#infoModalMess').modal()
              this._getMessageList(this.pageLimit, this.offset(0), false);
              this.messValue = ''
              this.currentName = window.localStorage.getItem('messName') || ''
            }
          })
        }
      } else {
        this.alertShow = true
      }
    },
    _getMessageList(limit = 0, offset = 0, addition = true) {
      this.$http.get(GLOBAL.apiUrl + "/message/list", { params: { limit: limit, offset: offset } }).then(res => {
        if (res.data.code === 0) {
          if (addition) {
            this.messList = this.messList.concat(res.data.data.data)
          } else {
            this.messList = res.data.data.data
          }
        }
        if (res.data.data.data.length >= this.pageLimit) {
          this.srollWitch = true;
        }
      })
    },
    scrollLoad() {
      this.$refs.messPanel.addEventListener('scroll', () => {
        const clientHeight = this.$refs.messPanel.clientHeight || document.body.clientHeight;
        const scrollTop = this.$refs.messPanel.scrollTop || document.body.scrollTop;
        const scrollHeight = this.$refs.messPanel.scrollHeight || document.body.scrollHeight;
        if (clientHeight + scrollTop + 100 >= scrollHeight) {
          if (this.srollWitch) {
            this.srollWitch = false;
            this._getMessageList(this.pageLimit, this.offset());
          }
        }
      })
    },
    refresh() {
      window.addEventListener('beforeunload', () => {
        // window.localStorage.setItem("vlogPageLimit", this.pageOffset);
        document.documentElement.scrollTop = 0
      })
    },
    offset(start) {
      if (start !== undefined) {
        this.pageOffset = start
      }
      const t = this.pageOffset
      this.pageOffset = this.pageLimit + this.pageOffset
      return t
    },
  }
}
</script>

<style scoped>
.message {
  padding: 0 10px;
}
.panel {
  margin-bottom: 10px!important;
}
.panel-body {
  background-color: rgba(223, 223, 223, 0.473);
  height: calc(100vh - 210px);
  overflow-y: auto;
}
.messOut {
  margin: 0 0 20px 0;
}
.messName {
  color: #618fb7;
  font-size: 12px;
  display: inline-block;
  min-width: 20px;
}
.messInner {
  padding: 5px 10px;
  border-radius: 10px 10px 10px 0;
  background-color: #618fb7e6;
  color: rgb(235, 235, 235);
  display: inline-block;
}
.messTime {
  font-size: 10px;
  color: #999;
  margin-left: 10px;
}
.messPre {
  display: inline-block;
  color: #999;
  font-size: 12px;
}
.inputMess {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.secretCon {
  color: #F5F5F5;
  background-color: #999;
}
.tipInput {
  display: inline-block;
  margin: 5px 0 0 22%;
  color: #999;
  font-size: 12px;
}
.panel-primary {
  border: 1px solid #ddd;
  padding: 5px;
}
.messInputFix {
  border-top: 1px solid #ddd;
  padding: 5px 10px 0 5px;
  background-color: #f8f8f8;
  width: 100%;
  position: fixed;
  left: 0px;
  bottom: 37px;
}
</style>>