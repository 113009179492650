<template>
  <div>
    <div v-show="files === null || files.length === 0" style="
      position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);color: #5e5e5e;font-size: 20px">
      作者比较懒 ...
    </div>

    <div class="list-group col-sm-10 col-md-8 col-lg-6 col-sm-offset-1 col-md-offset-2 col-lg-offset-3">
      <button type="button" class="list-group-item"
              v-for="item in files" :key="item.id" v-on:click="goto('/blog/' + folder +'/' + item.name)">{{item.name}}&nbsp;
        <span v-for="flag in (item.flags ? item.flags.split(';') : [])" :key="flag">
          <span :class="'label label-' + (flag.indexOf(':') != -1 ? flag.split(':')[0] : 'info')">{{flag.indexOf(':') != -1 ? flag.split(':')[1] : flag}}</span>&nbsp;
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import GLOBAL from "../global_variable";

export default {
  name: "blogList",
  props: {
    folder: String
  },
  data: function() {
    return {
      files: null,
    }
  },
  watch: {
    folder: function() {
      this.getFileList();
    }
  },
  methods: {
    getFileList() {
      this.$http.get(GLOBAL.apiUrl + "/blog/list/" + this.folder).then(response => {
        const res = response['data'];
        if (res['code'] > 0) {
          console.log("Get File List Failed" + res['message']);
        } else {
          this.files = res['data'];
        }
      }, err => {
        console.log("Get File List Failed" + err)
      })
    },
    goto(path) {
      this.$router.push({path: path})
    }
  },
  created() {
    this.getFileList()
  }
}
</script>

<style scoped>

</style>