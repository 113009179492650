<template>
  <div id="tool">
    <section v-on:click="goto('/scanCodeResult/0')">
      <button type="button" class="btn btn-default btn-lg">
        <span class="glyphicon glyphicon-barcode" aria-hidden="true"></span> <br/>条形码
      </button>
    </section>
  </div>
</template>

<script>
export default {
  name: "tool",
  data() {
    return {

    }
  },
  methods: {
    goto(path) {
      this.$router.push({path: path})
    },
  }
}
</script>

<style scoped>
  #tool {
    padding: 10px;
  }
  .outTool {
    width: 150px;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 15px;
    text-align: center;
  }
  .outTool .ig {
    font-weight: 800;
    font-size: 20px;
  }
</style>
