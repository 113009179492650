<template>
  <div class="photos">
    <div class="photosMain">
      <section class="addPho" v-if="isShow">
        <input type="text" style="width: 20%" class="form-control" placeholder="相册名称" v-model="name">&nbsp;:&nbsp;
        <div class="input-group" style="width: 80%">
          <span class="input-group-addon">
            <input type="checkbox" v-model="selectSec">
          </span>
          <input type="text" class="form-control" placeholder="相册描述" aria-describedby="basic-addon2" v-model="description">
          <span class="input-group-addon" id="basic-addon2" @click="addPho" style="background: #337ab7; color: #fff">新建相册</span>
        </div>
      </section>
      <span v-if="isShow" class="tipInput">勾选表私密相册</span>
      <div class="col-sm-12 col-md-3" v-for="item in photoList" :key="item.id">
        <div class="thumbnail" @click="searchPho(item)">
          <span class="photoDes" style="text-shadow: 1px 1px 3px #000;">{{ item.description }}</span>
          <img class="photoPho" :src="apiUrl + '/file/image/' + item.cover" alt="" width="100%">
          <!-- <div style="width: 100%;min-height: 150px; background-color: #999"></div> -->
          <div class="caption">
            <span>
              <span class="glyphicon glyphicon-time" aria-hidden="true"></span>&nbsp;{{ item.createTime }}
            </span>
            <span>
              <span class="glyphicon glyphicon-tags" aria-hidden="true"></span>&nbsp;{{ item.name }}
              <span v-if="isShow" class="glyphicon glyphicon-trash" @click.stop="deletePho(item)"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="infoModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content" style="border-radius: 25px">
          <div class="modal-body" style="text-align: center;background-color: red;border-radius: 25px">
            <span style="color: white;font-size: 15px">{{systemInfo}}</span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Cookies from 'js-cookie';
import GLOBAL from '../global_variable';

export default {
  name: 'photos',
  data() {
    return {
      oFileInput: null,
      isShow: false,
      photoList: [],
      systemInfo: '',
      name: '',
      description: '',
      selectSec: true,
      apiUrl: GLOBAL.apiUrl
    }
  },
  mounted() {
    if (window.localStorage.getItem('refreshToken')) {
      this.isShow = true
    }
    this.getPhotos()
  },
  methods: {
    getPhotos() {
      this.$http.get(GLOBAL.apiUrl + "/album/list").then(res => {
        if (res.data.code === 0) {
          this.photoList = res.data.data
        }
      })
    },
    addPho() {
      this.$http.post(GLOBAL.apiUrl + "/album/add", {
          name: this.name,
          description: this.description,
          secret: this.selectSec ? 1 : 0
        }).then(response => {
          const res = response['data'];
          if (res['code'] > 0) {
            this.systemInfo = res['message'];
            this.$('#infoModal').modal()
          } else {
            this.getPhotos();
            this.name = '';
            this.description = '';
          }
        }, err => {
            this.systemInfo = err['data']['message'];
            this.$('#infoModal').modal()
        });
    },
    searchPho(val) {
      this.$router.push({
        path: '/photoInner',
        query: {
          id: val.id
        }
      })
    },
    deletePho(item) {
      this.$http.post(GLOBAL.apiUrl + "/album/remove", item.id).then(res => {
        if (res.data.code === 0) {
          this.getPhotos()
        }
      })
    }
  }
}
</script>

<style scoped>
.uploadInput {
  margin: 0 15px 10px 15px;
}
.photos{
  width: 100%;
  overflow-y: auto;
  padding: 0 15px;
}
.tipInput {
  width: 75%;
  display: inline-block;
  margin: 0px 0 5px 22%;
  color: #999;
  font-size: 12px;
}
.caption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999;
  font-size: 12px;
}
.addPho {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 10px 0;
}
.photoPho {
  opacity: 0.9;
  height: 180px;
  object-fit: cover;
}
.photoDes {
  position: absolute;
  top: 35%;
  left: 10%;
  color: white;
  font-size: 18px;
  font-weight: 800;;
  z-index: 1000;
}
</style>