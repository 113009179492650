<template>
  <div class="page-scan">
    <div class="dropdown" v-if="cameras.length > 0">
      <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
        {{ selectCamera.label }}
        <span class="caret"></span>
      </button>
      <button type="button" class="btn btn-default btn-sm" v-on:click="goback">
        <span class="glyphicon glyphicon-list-alt" aria-hidden="true"></span> 返回商品
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
        <li style="padding: 10px; cursor: pointer" v-for="item in cameras" :key="item.deviceId" @click="changeCamera(item)">{{ item.label }}</li>
      </ul>
    </div>
    <div style="width: 100%; height: 90%; position: absolute; display: flex; justify-content: center; align-items: center;">
      <div style="width: 90%; height: 160px; border: 3px solid #cccccc;"></div>
    </div>
    <!-- 扫码区域 -->
    <video ref="video" id="video" class="scan-video" autoplay></video>
    <!-- 提示语 -->
    <div v-show="tipShow" class="scan-tip"> {{tipMsg}} </div>

    <audio ref="audio" src="../../public/video/scan.mp3" loop=false></audio>
  </div>
</template>

<script>
import { BrowserMultiFormatReader } from '@zxing/library';

export default {
  name: 'scanCodePage',
  data() {
    return {
      loadingShow: false,
      codeReader: null,
      scanText: '',
      vin: null,
      tipMsg: '正在尝试识别....',
      tipShow: false,
      cameras: [],
      selectCamera: {},
    }
  },
  created() {
    this.codeReader = new BrowserMultiFormatReader();
    this.openScan();
  },
  destroyed(){
    this.codeReader.reset();
  },
  methods: {
    goback() {
      this.goto('/scanCodeResult/0');
    },
    async openScan() {
      this.codeReader.getVideoInputDevices().then((videoInputDevices) => {
        this.tipShow = true;
        this.tipMsg = '正在调用摄像头...';
        console.log('videoInputDevices', videoInputDevices);
        for (const i of videoInputDevices) {
          this.cameras.push(i);
        }
        this.selectCamera = this.cameras[this.cameras.length - 1];
        let firstDeviceId = this.selectCamera.deviceId;
        this.decodeFromInputVideoFunc(firstDeviceId);
      }).catch(err => {
        this.tipShow = false;
        console.error(err);
      });
    },
    changeCamera(device) {
      this.selectCamera = device;
      this.decodeFromInputVideoFunc(device.deviceId);
    },
    decodeFromInputVideoFunc(firstDeviceId) {
      this.codeReader.reset(); // 重置
      this.scanText = '';
      this.codeReader.decodeFromInputVideoDeviceContinuously(firstDeviceId, 'video', (result, err) => {
        this.tipMsg = '正在尝试识别...';
        this.scanText = '';
        if (result) {
          this.scanText = result.text;
          if (this.scanText) {
            this.$refs.audio.loop = false;
            this.$refs.audio.play();

            setTimeout(() => {
              this.tipMsg = '识别成功';
              this.tipShow = false;
              this.goto("/scanCodeResult/" + (this.scanText === '' ? '1' : this.scanText));
            }, 1000);

          }
        }
        if (err && !(err)) {
          this.tipMsg = '识别失败';
          setTimeout(() => {
            this.tipShow = false;
          }, 2000)
          console.error(err);
        }
      });
    },
    goto(path) {
      this.$router.push({path: path})
    },
  }
}
</script>

<style>
.scan-index-bar{
  background-image: linear-gradient( -45deg, #42a5ff ,#59cfff);
}
.van-nav-bar__title{
  color: #fff !important;
}
.scan-video{
  height: 80vh;
}
.scan-tip{
  width: 100vw;
  text-align: center;
  margin-bottom: 10vh;
  color: white;
  font-size: 5vw;
}
.page-scan{
  overflow-y: hidden;
  background-color: #363636;
}
</style>
