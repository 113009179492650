<template>
  <div style="display: flex;justify-content: center">
    <div v-if="login" class="col-md-10 col-xs-12">
      <!-- Nav tabs -->
      <ul class="nav nav-tabs" role="tablist">
        <li role="presentation" class="active"><a href="#waiting" aria-controls="home" role="tab" data-toggle="tab" v-on:click="getData('waitList')">待审批</a></li>
        <li role="presentation"><a href="#agreed" aria-controls="profile" role="tab" data-toggle="tab" v-on:click="getData('agreedList')">已批准</a></li>
        <li role="presentation"><a href="#canceled" aria-controls="messages" role="tab" data-toggle="tab" v-on:click="getData('canceledList')">已取消</a></li>
      </ul>

      <div style="min-height: 44px;text-align: right">
        <button v-show="approval" class="btn btn-info" style="margin-top: 10px;margin-right: 10px" v-on:click="operation(1)">批准</button>
        <button v-show="approval" class="btn btn-info" style="margin-top: 10px;" v-on:click="operation(-1)">取消</button>
      </div>

      <!-- Tab panes -->
      <div class="tab-content" style="height: calc(100vh - 190px);overflow-y: auto">
        <div role="tabpanel" class="tab-pane active" id="waiting">
          <table class="table table-bordered table-hover table-striped" style="">
            <thead>
              <tr>
                <th width="10%" style="text-align: center">
                  <div class="checkbox" style="margin: 0">
                    <label>
                      <input type="checkbox" name="all" v-on:click="all()">
                    </label>
                  </div>
                </th>
                <th width="10%">昵称</th>
                <th width="60%">内容</th>
                <th>私密</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in waitList" :key="item.id">
                <td style="text-align: center">
                  <div class="checkbox" style="margin: 0">
                    <label>
                      <input type="checkbox" name="approval" class="approval" :value="item.id">
                    </label>
                  </div>
                </td>
                <td>{{item.name}}</td>
                <td>{{item.content}}<br /><span style="font-size: 12px;color: #999">{{item.ip}} - {{item.address}}</span></td>
                <td>{{item.secret ? '是' : '否'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div role="tabpanel" class="tab-pane" id="agreed">
          <table class="table table-bordered table-hover table-striped" style="">
            <thead>
            <tr>
              <th width="20%">昵称</th>
              <th width="50%">内容</th>
              <th width="10%">操作人</th>
              <th width="15%">时间</th>
              <th>私密</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="item in agreedList" :key="item.id">
                <td>{{item.name}}</td>
                <td>{{item.content}}<br /><span style="font-size: 12px;color: #999">{{item.ip}} - {{item.address}}</span></td>
                <td>{{item.optName}}</td>
                <td>{{item.updateTime}}</td>
                <td>{{item.secret ? '是' : '否'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div role="tabpanel" class="tab-pane" id="canceled">
          <table class="table table-bordered table-hover table-striped" style="">
            <thead>
            <tr>
              <th width="20%">昵称</th>
              <th width="50%">内容</th>
              <th width="10%">操作人</th>
              <th width="15%">时间</th>
              <th>私密</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="item in canceledList" :key="item.id">
                <td>{{item.name}}</td>
                <td>{{item.content}}<br /><span style="font-size: 12px;color: #999">{{item.ip}} - {{item.address}}</span></td>
                <td>{{item.optName}}</td>
                <td>{{item.updateTime}}</td>
                <td>{{item.secret ? '是' : '否'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div v-if="!login" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);color: #5e5e5e;font-size: 20px">
      请先登录
    </div>

    <div class="modal fade" id="MAInfoModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content" style="border-radius: 25px">
          <div class="modal-body" style="text-align: center;background-color: red;border-radius: 25px">
            <span style="color: white;font-size: 15px">{{systemInfo}}</span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import Cookies from "js-cookie";
import GLOBAL from "@/global_variable";

export default {
  name: 'messageApproval',
  data: function() {
    return {
      type: {
        waitList: 0,
        agreedList: 1,
        canceledList: -1
      },
      waitList: [],
      agreedList: [],
      canceledList: [],
      login: false,
      approval: true,
      systemInfo: ''
    }
  },
  methods: {
    getData(type) {
      this.approval = type === 'waitList';
      if (window.localStorage.getItem('accessToken')) {
        this.$http.get(GLOBAL.apiUrl + "/message/approval", {params: {status: this.type[type], limit: 0, offset: 0}}).then(response => {
          const res = response['data'];
          if (res['code'] === 0) {
            this[type] = res['data']['data'];
            this.login = true;
          }
        }, err => {
          this.systemInfo = err['data']['message'];
          this.$('#MAInfoModal').modal()
          location.reload();
        })
      } else {
        this.login = false;
      }
    },
    operation(status, reason) {
      const checkedList = [];
      this.$("input[name='approval']:checked").each((index, item) => {
        checkedList.push(item.value);
      });
      if (checkedList.length > 0) {
        this.$http.post(GLOBAL.apiUrl + "/message/approval", {ids: checkedList, status, reason}).then(response => {
          const res = response['data'];
          if (res['code'] === 0) {
            this.getData('waitList');
          }
        }, err => {
          this.systemInfo = err['data']['message'];
          this.$('#MAInfoModal').modal()
        })
      }
    },
    all() {
      let isAll = false;
      this.$("input[name='all']:checked").each(() => {
        isAll = true;
      });
      this.$(".approval").prop("checked", false);
      if (isAll) {
        this.$(".approval").prop("checked", true);
      }
    }
  },
  created() {
    this.getData('waitList');
    this.$('#MAInfoModal').on('close.bs.alert', function () {
      location.reload();
    })
  }
}
</script>
