<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="cacheModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" data-backdrop="static">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="myModalLabel">商品信息</h4>
          </div>
          <div class="modal-body">
            <form class="form-inline">
              <div class="form-group">
                <label for="code">条形码</label>
                <input class="form-control" id="code" v-model="scanInfo.code">
                <button type="button" v-if="showMulu" class="btn btn-default btn-sm" v-on:click="scanInfo.code = generateCode()">
                  <span class="glyphicon glyphicon-retweet" aria-hidden="true"></span> 随机生成
                </button>
              </div><br />
              <div class="form-group">
                <label for="name">商品名</label>
                <input class="form-control" id="name" v-model="scanInfo.text">
              </div><br />
              <div class="form-group">
                <label for="money">出售价(件)</label>
                <input class="form-control" id="money" v-model="scanInfo.value" :disabled="!showMulu">
              </div>
            </form>
          </div>
          <div v-if="!showMulu" class="butSty">
            <button type="button" class="btn btn-default" v-on:click="getInfo(scanInfo.code)">查询</button>
            <button type="button" class="btn btn-default" v-on:click="addToList(scanInfo)" :disabled="scanInfo.value === null" data-dismiss="modal">放入</button>
          </div>
          <div v-if="showMulu" class="butSty">
            <button v-if="scanInfo.id === undefined || scanInfo.id === null" type="button" class="btn btn-default" v-on:click="add(scanInfo)">录入</button>
            <button v-if="scanInfo.id !== undefined && scanInfo.id !== null" type="button" class="btn btn-default" v-on:click="edit(scanInfo)">更新</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="queryModal" tabindex="-1" role="dialog" aria-labelledby="myQueryModalLabel" aria-hidden="false" data-backdrop="static">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="myQueryModalLabel">请选择商品</h4>
          </div>
          <div class="modal-body">
            <table class="table table-bordered table-hover table-striped">
              <thead>
              <tr>
                <th></th>
                <th>商品名称</th>
                <th>条形码</th>
                <th>单价</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in queryList" :key="item.code">
                <td>
                  <input type="radio" name="radios" :value="index" v-model="querySelected">
                </td>
                <td>{{item.text}}</td>
                <td>{{item.code}}</td>
                <td>{{item.value}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="butSty">
            <button type="button" class="btn btn-default" v-on:click="selectItem()">确认</button>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加的列表 -->
    <div v-if="!showMulu">
      <div style="display: flex;justify-content: center">
        <div class="col-md-10 col-xs-12">
          <button type="button" class="btn btn-default btn-sm" v-on:click="openModel()">
            <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span> 输入
          </button>
          <button type="button" class="btn btn-default btn-sm" v-on:click="goto('/scanCodePage')">
            <span class="glyphicon glyphicon-camera" aria-hidden="true"></span> 扫描
          </button>
          <button type="button" class="btn btn-default btn-sm" v-on:click="resetList()">
            <span class="glyphicon glyphicon-erase" aria-hidden="true"></span> 重置
          </button>
          <button type="button" class="btn btn-default btn-sm" v-on:click="gotoMulu()">
            <span class="glyphicon glyphicon-list" aria-hidden="true"></span> 管理目录
          </button>
          <table class="table table-bordered table-hover table-striped">
            <thead>
            <tr>
              <th width="25%">商品名称</th>
              <th>单价</th>
              <th width="20%">数量</th>
              <th>总价</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in addList" :key="item.code">
              <td>{{item.text}}</td>
              <td>{{item.value}}</td>
              <td>
                <div style="display: flex;justify-content: center">
                  <button type="button" class="btn btn-default btn-xs" v-on:click="removeCount(item, index)">
                    <span class="glyphicon glyphicon-minus" aria-hidden="true"></span>
                  </button>
                  <input class="form-control input-sm" style="width: 40px" v-model="item.count">
                  <button type="button" class="btn btn-default btn-xs" v-on:click="item.count++">
                    <span class="glyphicon glyphicon-plus" aria-hidden="true"></span>
                  </button>
                </div>
              </td>
              <td>{{item.value * item.count}}</td>
            </tr>
            <tr>
              <td><b>总计</b></td>
              <td></td>
              <td>{{addCount}}</td>
              <td>{{addValue}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>


    <div style="display: flex;justify-content: center" v-if="showMulu">
      <div v-if="!login" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);color: #5e5e5e;font-size: 20px">
        请先登录
      </div>
      <div v-if="login"  class="col-md-10 col-xs-12">
        <button type="button" class="btn btn-default btn-sm" v-on:click="openModel()">
          <span class="glyphicon glyphicon-plus" aria-hidden="true"></span> 录入
        </button>
        <button type="button" class="btn btn-default btn-sm" v-on:click="goto('/scanCodePage')">
          <span class="glyphicon glyphicon-camera" aria-hidden="true"></span> 扫描
        </button>
        <button type="button" class="btn btn-default btn-sm" v-on:click="gobackItem()">
          <span class="glyphicon glyphicon-list-alt" aria-hidden="true"></span> 返回商品
        </button>
        <table class="table table-bordered table-hover table-striped">
          <thead>
          <tr>
            <th style="min-width: 80px">商品</th>
            <th>条形码</th>
            <th>价格</th>
            <th style="width: 50px">操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in list" :key="item.id">
            <td>{{item.text}}</td>
            <td>{{item.code}}</td>
            <td>{{item.value}}</td>
            <td>
              <button type="button" class="btn btn-default btn-sm" v-on:click="openModel(item)">
                <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span> 编辑
              </button>
              <button type="button" class="btn btn-default btn-sm" v-on:click="remove(item.id)">
                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span> 删除
              </button>
              <button type="button" class="btn btn-default btn-sm" v-if="item.status === 1" v-on:click="item.status = 0;edit(item)">
                <span class="glyphicon glyphicon-check" aria-hidden="true"></span> 已上架
              </button>
              <button type="button" class="btn btn-default btn-sm" v-if="item.status === 0" v-on:click="item.status = 1;edit(item)">
                <span class="glyphicon glyphicon-unchecked" aria-hidden="true"></span> 未上架
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <nav style="text-align: center;">
          <ul class="pagination" style="margin: 0">
            <li v-for="item in listPages" :key="item" :class="selectPage === item ? 'active' : ''" v-on:click="getList(item)">
              <span href="#">{{item}}<span class="sr-only">(current)</span></span>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="modal fade" id="MAInfoModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content" style="border-radius: 25px">
          <div class="modal-body" style="text-align: center;background-color: red;border-radius: 25px">
            <span style="color: white;font-size: 15px">{{systemInfo}}</span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GLOBAL from "@/global_variable";
import ScanCodePage from "./scanCodePage";

export default {
  name: "scanCodeResult",
  // eslint-disable-next-line vue/no-unused-components
  components: {ScanCodePage},
  props: {
    code: String
  },
  data: function() {
    return {
      list: [],
      queryList: [],
      addList: [],

      showQueryList: false,
      querySelected: 0,

      listLimit: 7,
      listOffset: 0,
      listCount: true,
      listPages: [],
      selectPage: 1,

      login: false,
      showMulu: false,

      scanInfo: {
        id: null,
        code: null,
        text: null,
        value: null,
        count: 1,
      },
      systemInfo: ''
    }
  },
  computed: {
    addCount() {
      let co = 0;
      this.addList.forEach(item => {
        co = co + item.count;
      })
      return co;
    },
    addValue() {
      let va = 0;
      this.addList.forEach(item => {
        va = va + (item.count * Number(item.value));
      })
      return va;
    }
  },
  methods: {
    selectItem() {
      this.scanInfo = {...this.queryList[this.querySelected], ...{"count": 1}};
      this.$('#queryModal').modal('hide');
      this.querySelected = 0;
    },
    openModel(item) {
      this.$('#cacheModal').modal();
      this.scanInfo = item === undefined ? {
        id: null,
        code: null,
        text: null,
        value: null,
        count: 1,
      } : item;
    },
    removeCount(item, index) {
      if (--item.count === 0) {
        this.addList.splice(index, 1);
      }
    },
    resetList() {
      this.addList = [];
      window.localStorage.setItem("itemList", JSON.stringify(this.addList));
    },
    addToList(item) {
      let ca = null;
      this.addList.forEach(i => {
        if (i.code === item.code) {
          ca = i;
        }
      });
      if (ca !== null) {
        ca.count++;
      } else {
        this.addList.push(JSON.parse(JSON.stringify(item)));
      }
      this.scanInfo = {
        id: null,
        code: null,
        text: null,
        value: null,
        count: 1,
      };
      this.$('#cacheModal').modal('hide');
    },
    getList(page) {
      if (window.localStorage.getItem('accessToken')) {
        this.$http.get(GLOBAL.apiUrl + "/scan/query/1", {
          params: {
            count: this.listCount,
            limit: this.listLimit,
            offset: (page - 1) * this.listLimit
          }
        }).then(response => {
          const res = response['data'];
          this.login = true;
          if (res['code'] === 0) {
            this.list = res['data']['data'];
            if (this.listCount) {
              console.log(res['data']['total'] + ' ' +this.listLimit)
              for (let i = 0; i < (res['data']['total'] / this.listLimit); i++) {
                this.listPages.push(i + 1);
              }
              this.listCount = false;
            }
            this.selectPage = page;
          } else {
            this.systemInfo = res['message'];
          }
        }, err => {
          this.systemInfo = err['body']['message'];
        })
      } else {
        this.showMulu = false;
        window.localStorage.setItem("showMulu", this.showMulu);
        this.login = false;
        this.systemInfo = '请先登录！';
        this.$('#MAInfoModal').modal()
      }
    },
    getInfo() {
      if (this.scanInfo.code === null && this.scanInfo.text === null) {
        return;
      }
      this.$http.get(GLOBAL.apiUrl + "/scan/query/1/list", {params: {"code": this.scanInfo.code, "text": this.scanInfo.text}}).then(response => {
        const res = response['data'];
        if (res['code'] === 0) {
          this.resData = res['data'];
          if (this.resData !== null && this.resData !== undefined && this.resData.length > 0) {
            if (this.resData.length === 1) {
              this.scanInfo.id = this.resData[0].id;
              this.scanInfo.text = this.resData[0].text;
              this.scanInfo.value = this.resData[0].value;
            } else {
              this.queryList = this.resData;
              this.$('#queryModal').modal();
            }
          }
          this.systemInfo = '';
        } else {
          this.systemInfo = res['message'];
        }
      }, err => {
        this.systemInfo = err['body']['message'];
      })
    },
    add(item) {
      this.$http.get(GLOBAL.apiUrl + "/scan/query/1/list", {params: {"code": item.code, "text": null}}).then(response => {
        const res = response['data'];
        if (res['code'] === 0) {
          this.resData = res['data'];
          if (this.resData !== null && this.resData !== undefined && this.resData.length > 0) {
            this.systemInfo = '当前商品已存在（' + this.resData[0].text + '/' + this.resData[0].code + ')';
            this.$('#MAInfoModal').modal()
          } else {
            this.$http.post(GLOBAL.apiUrl + "/scan/add", {
              type: 1,
              code: item.code,
              text: item.text,
              value: item.value
            }).then(response => {
              if (response) {
                this.getList(1);
                this.$('#cacheModal').modal('hide');
              }
            });
          }
        }
      })

    },
    edit(item) {
      this.$http.post(GLOBAL.apiUrl + "/scan/edit", {
        id: item.id,
        type: 1,
        code: item.code,
        text: item.text,
        value: item.value,
        status: item.status
      }).then(response => {
        if (response) {
          this.getList(this.selectPage);
          this.$('#cacheModal').modal('hide');
        }
      });
    },
    remove(id) {
      this.$http.post(GLOBAL.apiUrl + "/scan/remove/" + id, null).then(response => {
        if (response) {
          this.getList(this.selectPage);
        }
      });
    },
    goto(path) {
      this.$router.push({path: path})
      window.localStorage.setItem("itemList", JSON.stringify(this.addList));
    },
    change(res) {
      this.showLogin = res;
    },
    gobackItem() {
      this.showMulu = !this.showMulu;
      window.localStorage.setItem("showMulu", this.showMulu);
      if(this.code !== '0') {
        this.goto('/scanCodeResult/0')
      }
    },
    gotoMulu() {
      this.showMulu = !this.showMulu;
      window.localStorage.setItem("showMulu", this.showMulu);
      this.getList(1);
      if(this.code !== '0') {
        this.goto('/scanCodeResult/0')
      }
    },
    generateCode() {
      return new Date().getTime() + 'S' + Math.floor(Math.random()*(999-100)+100);
    }
  },
  created() {
    let itemList = window.localStorage.getItem("itemList");
    if (itemList !== null && itemList !== undefined) {
      this.addList = JSON.parse(itemList);
    }
  },
  mounted() {
    if (this.code !== '0') {
      if(this.code === '1') {
        this.systemInfo = '扫描失败，请重试！';
        this.$('#MAInfoModal').modal()
      } else {
        this.scanInfo.code = this.code;
        this.getInfo();
        this.$('#cacheModal').modal();
      }
    }
    let showMulus = window.localStorage.getItem("showMulu");
    if (showMulus !== null && showMulus !== undefined) {
      this.showMulu = showMulus === 'true';
      if (this.showMulu) {
        this.getList(1);
      }
    }
  }
}
</script>

<style scoped>
.goodsName {
  margin: 20px;
  font-size: 50px;
  color: #ff8a8f;
  text-align: center;
}
.goodsMon {
  margin: 20px;
  font-size: 50px;
  color: coral;
  text-align: center;
}
.butSty {
  display: flex;
  justify-content: flex-end;

}
</style>
