<template>
  <div id="app">
    <nav class="navbar navbar-default navbar-fixed-top">
      <div class="container-fluid">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header">
          <button type="button" class="navbar-toggle collapsed" style="padding: 6px 10px;color:#777;" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
<!--            <span class="icon-bar"></span>-->
<!--            <span class="icon-bar"></span>-->
<!--            <span class="icon-bar"></span>-->
            <span class="glyphicon glyphicon-list" aria-hidden="true"></span>
            <span style="margin-left: 5px;margin-top: 5px">更多</span>
          </button>
          <div>
          <a class="menu navbar-brand" v-on:click="goto('/home')">YYHKL</a>

          <ul class="hidden-xs nav navbar-nav" style="margin: 0">
            <li class="menu" id="mood1"><a v-on:click="goto('/mood', 'mood1')">生活随笔</a></li>
            <li class="menu" id="photo1"><a v-on:click="goto('/photo', 'photo1')">照片墙</a></li>
          </ul>
          <div class="visible-xs">
            <ul class="nav navbar-nav" style="display: flex; margin: 0; padding: 5px 0 0 0">
              <li class="menu" id="mood2"><a v-on:click="goto('/mood', 'mood2')">生活随笔</a></li>
              <li class="menu" id="photo2"><a v-on:click="goto('/photo', 'photo2')">照片墙</a></li>
            </ul>
          </div>
          </div>
        </div>

        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul class="nav navbar-nav">
            <li class="menu dropdown" id="blog">
              <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                文章分享 <span class="caret"></span>
              </a>
              <ul class="dropdown-menu">
                <li v-for="item in blogType" :key="item.code"><a v-on:click="goto(item.path, 'blog')">{{item.name}}</a></li>
                <!--<li><a v-on:click="goto('/blog/it', 'blog')">IT技术类</a></li>-->
                <!--<li><a v-on:click="goto('/blog/life', 'blog')">生活日记类</a></li>-->
              </ul>
            </li>

<!--            <li class="menu" id="mood"><a v-on:click="goto('/mood', 'mood')">生活随笔</a></li>-->

<!--            <li class="menu" id="photo"><a v-on:click="goto('/photo', 'photo')">照片墙</a></li>-->

            <li class="menu" id="message"><a v-on:click="goto('/message', 'message')">留言板</a></li>
            <li class="menu" id="tool"><a v-on:click="goto('/tool', 'tool')">工具箱</a></li>
          </ul>
          <ul class="nav navbar-nav navbar-right">
            <li class="disabled"><a v-if="userName == null && expireDate != null">{{expireDate}}</a></li>
            <li><a data-toggle="modal" data-target="#loginModal" v-if="userName == null">登录</a></li>

            <li class="menu dropdown" v-if="userName != null">
              <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                {{userName}} <span class="caret"></span>
              </a>
              <ul class="dropdown-menu">
                <li><a v-on:click="goto('/account')">密码保险箱</a></li>
                <li><a v-on:click="goto('/visitor')">访客列表</a></li>
                <li><a v-on:click="goto('/message/approval')">审核留言</a></li>
                <li><a v-on:click="cleanFile()">清空无效文件</a></li>
                <li role="separator" class="divider"></li>
                <li><a v-on:click="goto('/translate')">抽奖</a></li>
                <li><a v-on:click="goto('/bill')">账单</a></li>
                <li role="separator" class="divider"></li>
                <li :class="disabled"><a v-on:click="setNoRecordDay()">{{expireDate === null ? '隐藏此设备记录' : expireDate}}</a></li>
                <li><a v-on:click="logout()">退出</a></li>
              </ul>
            </li>
          </ul>
        </div><!-- /.navbar-collapse -->
      </div><!-- /.container-fluid -->
    </nav>

    <!-- Modal -->
    <div class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" data-backdrop="static">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="myModalLabel">用户登录</h4>
          </div>
          <div class="modal-body">
            <form class="form-inline">
              <div class="form-group">
                <label for="username" style="margin-right: 10px">用户名</label>
                <input type="email" class="form-control" id="username" v-model="username" placeholder="email">
              </div>
              <br />
              <br />
              <div class="form-group">
                <label for="password" style="letter-spacing: 12px">密码</label>
                <input type="password" class="form-control" id="password" v-model="password" placeholder="password">
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
            <button type="button" class="btn btn-primary" v-on:click="login()">登录</button>
            <br />
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="infoModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content" style="border-radius: 25px">
          <div class="modal-body" style="text-align: center;background-color: red;border-radius: 25px">
            <span style="color: white;font-size: 15px">{{systemInfo}}</span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
        </div>
      </div>
    </div>

    <div style="margin-top: 50px; min-height: calc(100vh - 80px); background-color: #f8f8f8; padding-top: 10px">
      <router-view></router-view>
    </div>

    <div style="padding-top: 10px;text-align: center;color: #808080;font-size: 14px;background-color: #f8f8f8">
      © 2021 yyhkl.com All Rights Reserved
      <a href="https://beian.miit.gov.cn/" id="ipc_num" target="_blank">豫ICP备2021032359号-1</a>
    </div>

  </div>
</template>

<script>
import GLOBAL from './global_variable';
// import Cookies from 'js-cookie';
import JSEncrypt from 'jsencrypt';

export default {
  name: 'App',
  data() {
    return {
      username: null,
      password: null,
      systemInfo: '',
      userName: null,
      blogType: [],
      expireDate: null,
      disabled: '',
      remainSeconds: ''
    }
  },
  mounted() {
      this.popstateListener()
  },
  methods: {
    goto(path, id) {
      this.$('.menu').removeClass('active');
      if (id) {
        this.$('#' + id).addClass('active')
      }
      this.$router.push({path: path})
      this.$('.navbar-toggle').addClass('collapsed');
      this.$('.navbar-toggle').attr('aria-expanded', false);
      this.$('#bs-example-navbar-collapse-1').removeClass('in');
      this.$('#bs-example-navbar-collapse-1').attr('aria-expanded', false);
    },

    login() {
      if (this.username === null || this.username === '' ||  this.username === undefined
          || this.password === null || this.password === '' || this.password === undefined) {
        this.systemInfo = "用户名密码不能为空";
        this.$('#infoModal').modal()
      } else {
        const jse = new JSEncrypt();
        jse.setPublicKey('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCN6W0FeTVybtIBuhT+28ccc5gCuncZ6s2RCql/Gyk4ncjCn+4VNdF8rRraGE9Lb+sHcaA/1pPgHDCYPYmoo2XFBW8yihpp/YL4K4/ckdhwcE7T+opd+CuqPswFmJS2FynXxBD5jEHcCvyTWFG/Z5Er2OezeMUSUUO80L03jSkwjwIDAQAB')
        this.$http.post(GLOBAL.apiUrl + "/login", {
          username: this.username,
          password: jse.encrypt(this.password)
        }).then(response => {
          const res = response['data'];
          if (res['code'] > 0) {
            this.systemInfo = res['message'];
            this.$('#infoModal').modal()
          } else {
            window.localStorage.setItem('accessToken', res['data']['accessToken'])
            window.localStorage.setItem('refreshToken', res['data']['refreshToken'])
            this.getUser();
            this.$('#loginModal').modal('hide')
            location.reload();
          }
        }, err => {
            this.systemInfo = err['data']['message'];
            this.$('#infoModal').modal()
        });
      }
    },
    logout() {
      this.$http.post(GLOBAL.apiUrl + "/logout");
      window.localStorage.removeItem('accessToken')
      window.localStorage.removeItem('refreshToken')
      this.userName = null
      location.reload();
    },
    getUser() {
      if (window.localStorage.getItem('accessToken')) {
        this.$http.get(GLOBAL.apiUrl + "/user").then(response => {
          const res = response['data'];
          if (res['code'] > 0) {
            this.systemInfo = res['message'];
            this.$('#infoModal').modal()
          } else {
            this.userName = res['data']['name'];
          }
        }, err => {
            this.systemInfo = err['data']['message'];
            this.$('#infoModal').modal()
        })
      }
    },
    getBogType() {
      this.$http.get(GLOBAL.apiUrl + "/blog/type/list").then(response => {
        const res = response['data'];
        if (res['code'] > 0) {
          this.systemInfo = res['message'];
          this.$('#infoModal').modal()
        } else {
          this.blogType = res['data'];
        }
      }, err => {
          this.systemInfo = err['data']['message'];
          this.$('#infoModal').modal()
      })
    },
    popstateListener() {
        const _this = this;
        window.addEventListener("popstate", function() {
            _this.$(".modal-backdrop").hide();
        }, false);
    },
    cleanFile() {
        if (window.localStorage.getItem('accessToken')) {
            this.$http.post(GLOBAL.apiUrl + "/file/clean").then(response => {
                const res = response['data'];
                if (res['code'] > 0) {
                    this.systemInfo = res['message'];
                    this.$('#infoModal').modal()
                } else {
                    alert(res['data'].join('\n'))
                }
            }, err => {
                this.systemInfo = err['data']['message'];
                this.$('#infoModal').modal()
            })
        }
    },
    async getKey() {
      if (sessionStorage.getItem("key") == null) {
          const response = await this.$http.get(GLOBAL.apiUrl + "/key")
          const res = response['data'] || {code: 1, message: response['message']};
          if (res['code'] > 0) {
              console.log("Get Key Failed" + res['message']);
          } else {
              sessionStorage.setItem('key', res['data'])
          }
      }
      if (localStorage.getItem("no_record_day") != null) {
          const response = await this.$http.get(GLOBAL.apiUrl + "/no_record/expire/" + localStorage.getItem("no_record_day"));
          const res = response['data'] || {code: 1, message: response['message']};
          if (res['code'] === 0 && res['data'] != null) {
              const seconds = res['data'];
              this.expireDate =  this.formatSecond(seconds);
              this.disabled = (seconds > 24 * 60 * 60) ?  'disabled' : '';
              this.remainSeconds = seconds;
          } else {
              localStorage.removeItem("no_record_day");
              this.disabled = ''
          }
      }
    },
    setNoRecordDay() {
      if (localStorage.getItem("no_record_day") != null) {
          if (this.remainSeconds > 24 * 60 * 60) {
              return;
          }
      }
      this.$http.get(GLOBAL.apiUrl + "/no_record_day").then(response => {
          const res = response['data'];
          if (res['code'] > 0) {
              console.log("Get Key Failed" + res['message']);
          } else {
              localStorage.setItem('no_record_day', res['data']['uuid']);
              this.expireDate = this.formatSecond(res['data']['days']);
              this.disabled = 'disabled';
          }
      }, err => {
          console.log("Get Key Failed" + err)
      })
    },
    prefixZero(num, n) {
        return (Array(n).join(0) + num).slice(-n);
    },
    formatSecond(seconds) {
        var day = Math.floor( seconds / (24 * 3600));
        var hour = Math.floor((seconds - day * 24 * 3600) / 3600);
        var minute = Math.floor((seconds - day * 24 * 3600 - hour * 3600) /60);
        var second = seconds - day * 24 * 3600 - hour * 3600 - minute * 60;
        return day + "天"  + hour + "时" + minute + "分" + second + "秒";
    }
  },
  created() {
    this.getKey().then(() => {
        this.getUser();
        this.getBogType();
    });

  }
}
</script>

<style>
::-webkit-scrollbar-track{
  background-color: #FFFFFF
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 3px solid #FFFFFF;
  margin: 5px;
  background-color: #E0E0E0;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background: #AFAFAF;
}

::-webkit-scrollbar {
  width: 15px;
  height: 20px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 13px;
  height: 15px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  /*margin-top: 60px;*/
}
.navbar {
  /* margin-bottom: 0px!important; */
}
.menu {
  cursor: pointer;
}
.menu:hover{
  background-color: #e7e7e7;
}
</style>
