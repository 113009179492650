<template>
  <div>
    <img src="../../public/images/01.gif" alt="" srcset="" width="150px">
    <div v-if="login" class="col-sm-10 col-md-8 col-lg-6 col-sm-offset-1 col-md-offset-2 col-lg-offset-3" id="choose">
      <div id="top">
        <div id="title">抽到啥买啥</div>
        <div id="wrap">{{currentItem.name}}</div>
        <div id="context">
          <div class="col-xs-6 col-sm-5 col-md-4 col-lg-3" style="width: 30%;height: 100%;padding: 0;overflow-y: auto">
            <table class="table table-striped table-hover table-striped">
              <thead>
                <tr>
                  <th width="80%">名称</th>
                  <th v-on:click="editList = !editList">编辑</th>
                </tr>
              </thead>
              <tbody>
              <tr v-for="item in itemList" :key="item.id">
                <td>{{item.name}}</td>
                <td>
                  <span v-if="editList" class="opt" v-on:click="removeProduct(item.id)" style="text-align: center">
                    <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                  </span>
                  <span v-if="!editList" class="opt" v-on:click="addItem(item)" style="text-align: center"> >>> </span>
                </td>
              </tr>
              <tr v-if="editList">
                <td><input type="text" class="form-control" id="productName" v-model="newProductName"></td>
                <td>
                  <button class="btn btn-default" v-on:click="addProduct(newProductName)">
                    <span class="glyphicon glyphicon-save" aria-hidden="true"></span>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-xs-6 col-sm-5 col-md-4 col-lg-3" style="width: 70%;height: 100%;border-left: 1px solid #ccc;padding: 0;overflow-y: auto">
            <table class="table table-striped table-hover table-striped">
              <thead>
              <tr>
                <th>名称</th>
                <th width="40%">权重</th>
                <th width="20%">操作</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in selectedList" :key="item.id">
                <td style="vertical-align: middle">{{item.name}}</td>
                <td><input type="number" class="form-control" id="exampleInputName2" v-model="item.weight"></td>
                <td class="opt" v-on:click="removeItem(item.id)" style="text-align: center;vertical-align: middle"> 移除 </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <button style="width: 50%" class="btn btn-primary" type="button" v-on:click="start()" :disabled="startDisabled">开始</button>
          <button style="width: 50%" class="btn btn-primary" type="button" v-on:click="stop()" :disabled="stopDisabled">停止</button>
        </div>
      </div>
    </div>

    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="result" id="result" aria-hidden="false">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-body" style="text-align: center; background-color: #618fb7;">
            <span style="color: white;font-size: 14px">{{currentItem.name}}</span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!login" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);color: #5e5e5e;font-size: 20px">
      请先登录
    </div>
    <div class="modal fade" id="MAInfoModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content" style="border-radius: 25px">
          <div class="modal-body" style="text-align: center;background-color: red;border-radius: 25px">
            <span style="color: white;font-size: 15px">{{systemInfo}}</span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Cookies from "js-cookie";
import GLOBAL from "@/global_variable";

export default {
  name: "translate",
  data: function() {
    return {
      login: false,
      itemList: [
      ],
      editList: false,
      selectedList: [],
      selectedId: new Set(),
      defaultWeight: 1,
      startDisabled: false,
      stopDisabled: true,
      currentItem: {id: null, name: "🎇奖池🎇"},
      newProductName: null,
      startTimer: null,
      stopTimer: null,
      rate: 50,
      preNum: 0,
      systemInfo: ''
    }
  },
  methods: {
    getData() {
      if (window.localStorage.getItem('accessToken')) {
        this.$http.get(GLOBAL.apiUrl + "/luck/list", {}).then(response => {
          const res = response['data'];
          if (res['code'] === 0) {
            this.itemList = res['data'];
            this.login = true;
          }
        }, err => {
          this.systemInfo = err['data']['message'];
          this.$('#MAInfoModal').modal()
          location.reload();
        })
      } else {
        this.login = false;
      }
    },
    addProduct(name) {
      if (name !== '' && name !== null) {
        this.$http.post(GLOBAL.apiUrl + "/luck/add", name).then(response => {
          const res = response['data'];
          if (res['code'] === 0) {
            this.newProductName = null;
            this.getData();
          }
        }, err => {
          this.systemInfo = err['body']['message'];
          this.$('#MAInfoModal').modal()
        })
      }
    },
    removeProduct(id) {
      this.$http.post(GLOBAL.apiUrl + "/luck/remove", id).then(response => {
        const res = response['data'];
        if (res['code'] === 0) {
          this.getData();
        }
      }, err => {
        this.systemInfo = err['data']['message'];
        this.$('#MAInfoModal').modal()
        location.reload();
      })
    },
    addItem(item) {
      if (!this.selectedId.has(item.id)) {
        this.selectedList.push({...item, weight: this.defaultWeight});
        this.selectedId.add(item.id);
      }

    },
    removeItem(id) {
      this.selectedList.forEach((item, i) => {
        if (item.id === id) {
          this.selectedList.splice(i, 1);
          this.selectedId.delete(id);
          return;
        }
      });
    },
    changeItem() {
      let pre = this.preNum;
      this.preNum = Math.floor(Math.random() * this.selectedList.length);
      if(this.preNum === pre){
        if(this.preNum === 0){
          this.preNum++;
        }
        else{
          this.preNum--;
        }
      }
      this.currentItem = this.selectedList[this.preNum];
    },
    start() {
      if (this.selectedList.length > 0) {
        this.startDisabled = true;
        this.stopDisabled = false;
        this.startTimer = setInterval(this.changeItem, this.rate);
      }
    },
    stop() {
      let _this = this;
      this.stopTimer = setInterval(function(){
        _this.rate += 100
        clearInterval(_this.startTimer);
        if (_this.rate > 1000) {
          clearInterval(_this.stopTimer);
          _this.startDisabled = false;
          _this.stopDisabled = true;
          _this.rate = 50;
          _this.preNum = 0;
          _this.$('#result').modal()
        } else {
          _this.startTimer = setInterval(_this.changeItem, _this.rate);
        }
      }, 800);
    }
  },
  created() {
    this.getData();
    this.$('#MAInfoModal').on('close.bs.alert', function () {
      location.reload();
    })
  }
}
</script>

<style scoped>
#top{
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 8px rgb(220, 220, 244);
  background-color:#fff;
}
#title{
  height: 30px;
  line-height: 30px;
  padding: 0 0 0px 10px;
  font-weight:bold;
}
#wrap{
  background-color: #f7a6a6f1;
  padding:30px 0;
  margin: 5px 10px;
  text-align:center;
  border-radius: 20px;
  font-size:20px;
  font-weight:bold;
  color:rgb(217, 104, 104);
}
#context{
  height: calc(100vh - 310px);
}
.opt:hover{
  cursor: pointer;
  color: #000000;
  text-decoration:underline
}

</style>